import React,  { forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from "../lib/MsgBox";

const DrawerDeleteRadius = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      submitForm: false,
      subscription_date: ''
      });
    var obj;

    useImperativeHandle(ref, () => ({
      openForm: (id) => {
        setState({ isForm: true, id: id}) 

        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

  const onDelete = (id) => {
        loadingOn()
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization",cookies.token);
       
        const requestOptions = {
          method: "DELETE",
          headers: myHeaders,
          redirect: "follow"
        };

        fetch(props.config.hostApi + 'devices/'+id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(result.success){
              refreshParent()
              loadingOff()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Delete Radius',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            console.log('disini')
          })
          
          
  }
 
  const loadingOn = () =>{
    console.log('Loading On')
    setLoading(true)
  }
  const loadingOff = () =>{
    console.log('LOading Off')
    setLoading(false)
  }
  const refreshParent = () => {
    props.refreshRadiusDelete()
    setState({isForm:false})
  }
  
  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>

            <Mui.Toolbar disableGutters sx={{bgcolor: "#FBDEDF", color:'#DC2625'}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Delete Radius &raquo; ({state.id})
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.NotInterested />}
                    onClick={()=>{
                        onDelete(state.id)
                    }}
                    loadingPosition="start"
                    > Delete
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                          <Mui.Grid container>
                           
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.Grid item md={12} 
                                      padding={3}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center">
                                      <Mui.Avatar sx={{ bgcolor: "#FBDEDF", color:'#DC2625', width: 80, height:80 }}>
                                          <MuiIcon.NotInterested sx={{fontSize:'40px'}} />
                                      </Mui.Avatar>
                                  </Mui.Grid>
                                  <Mui.Grid
                                    justifyContent="center"
                                    align="center"
                                    p={'0px 3px'}
                                      >
                                      <Mui.Typography variant='h6' p={'0px 2px'}>Are you sure you want to Delete this Radius?</Mui.Typography>
                                      <Mui.Typography variant='subtitle1'>By deleting Radius "{state.id}", all task assigned to that Radius will also be delete.</Mui.Typography>
                                  </Mui.Grid>

                            </Mui.Grid>
                            
                      </Mui.Grid>
                  
            </Mui.DialogContent>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerDeleteRadius;
