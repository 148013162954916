import * as React from 'react';
import * as MuiJoy from '@mui/joy';
import { useParams } from "react-router-dom";
import MsgBox from '../lib/MsgBox';

export default function Pay(props) {
  const [loadingchp,setLoadingchp]=React.useState();
  const [error,setError] = React.useState();
  const [isMsgBox, setisMsgBox] = React.useState({
    open: false,
    status: 'ok',
    title:'',
    msg: '',
    action:''
  });

  const { id } = useParams();

  const pay = (id) => {
    setLoadingchp(true)
      
    const myHeaders = new Headers();
    myHeaders.append("Authorization",'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJwaG9uZSI6IjA4NzY2NTQ1MzQyMSIsImpvYl9pZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsImlhdCI6MTcwODQ4NTc4N30.G9I2Y1_TrOrNRh3DyWM4-IhFfI1fY1HSDTGTz_AcxJ8');
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams(new FormData());
    urlencoded.append("id[]", id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };


    fetch(props.config.hostApi + 'invoice/pay/multiple', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            console.log(result)
            setError([])
            setLoadingchp(false)
            setisMsgBox({
              open: true,
              status: 'success',
              title:'Payment',
              msg: 'Pay invoice successfully',
            })
          }else{
            console.log(result.errors)
            setError(result.errors)
            setLoadingchp(false)
            setisMsgBox({
              open: true,
              status: 'error',
              title:'Payment',
              msg: result.message,
            })
          }
        })
        .then(()=>{
          setLoadingchp(false)
        })
        
  }
 

  return (
    <div>

      <MsgBox config={isMsgBox} />
      <MuiJoy.Button
        sx={{margin:'20px'}}
        onClick={()=>{
          pay(id)
        }}
      >
        Pay Invoice : {id}
      </MuiJoy.Button>
      
    </div>
  );
}