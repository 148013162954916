import './App.css';
import { Routes, Route } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Dashboard from './pages/Dashboard';
import Customers from "./pages/Customers";
import ServiceOrders from "./pages/ServiceOrders";
import Radius from './pages/devices/Radius';
import SignIn from './pages/Signin';
import BNG from './pages/devices/Bng';
import Taxonomy from './pages/Taxonomy';
import Page404 from './pages/Page404';
import Products from './pages/Products';
import Clusters from './pages/Clusters';
import Users from './pages/Users';
import Olt from './pages/devices/Olt';
import BillingTemplates from './pages/BillingTemplates'
import DeviceType from './pages/DeviceType';
import Test from './pages/Test'
import Invoices from './pages/Invoices';
import Pay from './pages/Pay';

function App() {
  
  const config = { 
    appTitle : "Network Management System",
    locStorage : 'bas_cache',
    home : 'http://localhost:3000/',
    hostApi : 'https://app.netcheck.my.id/'
  }
  const [cookies] = useCookies(['bas_']);

  return (
    <Routes>
      <Route path="*" element={<Page404 />} />
      <Route path="/" element={<Dashboard config={config} cookies={cookies} />} />
      
      <Route path="/signin" element={<SignIn config={config} />} />
      <Route path="/signout" element={<SignIn action="signout" />} />

      <Route path="/dashboard" element={<Dashboard config={config} />} />

      <Route path="/products" element={<Products config={config} />} />
      <Route path="/clusters" element={<Clusters config={config} />} />

      <Route path="/radius" element={<Radius config={config} />} /> 
      <Route path="/bng" element={<BNG config={config} />} />
      <Route path="/olt" element={<Olt config={config} />} />

      <Route path="/serviceorder" element={<ServiceOrders config={config} />} />
      <Route path="/customers" element={<Customers config={config}/>} />

      <Route path="/device-type" element={<DeviceType config={config} />} />
      <Route path="/users" element={<Users config={config} />} />
      <Route path="/taxo" element={<Taxonomy config={config} />} /> 

      <Route path="/billing-templates" element={<BillingTemplates config={config} />} />
      <Route path="/invoices" element={<Invoices config={config} />} />

      <Route path="/test" element={<Test config={config} />} /> 
      <Route path="/pay/:id" element={<Pay config={config} />} /> 

    </Routes>
  );
}

export default App;
