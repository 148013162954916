import React,{ useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import MsgBox from '../lib/MsgBox';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {' Last40 Studio'}
    </Typography>
  );
}


export default function SignIn(props) {
  let navigate = useNavigate();
  const [cookie,setCookie, removeCookie] = useCookies(['bas_']);
  const [loading, setLoading] = React.useState(false);
  const [isMsgBox, setisMsgBox] = React.useState({
    open: false,
    status: 'ok',
    title:'',
    msg: '',
    action:''
  });
  const [form, setForm] = React.useState({
      username: '',
      password: ''
  });
  const handleSubmit = (event) => {
    console.log('submitted')
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const header ={
      'Accept': 'application/json'
    }
    axios.post( 
      props.config.hostApi + 'login',
      { 
        username: data.get('username'),
        password: data.get('password'),
      }, 
      { headers: header })
        .then(res => {
          setCookie('token', res.data.data.token);
          setCookie('user_id', res.data.data.id);
          setCookie('username', res.data.data.username);
          setCookie('name', res.data.data.name);
          setCookie('phone', res.data.data.phone);
          setCookie('job_id', res.data.data.job_id);
          setCookie('account_id', res.data.data.account_id);
          navigate('/dashboard');
      })
      .catch((error) => {
        console.log('submitted error')
        setisMsgBox({
          open:true,
          title:'Login Failed', 
          status:'error', 
          msg: error.response.data.message,
          action:''
        })
      })

      setTimeout(function () {
        setLoading(false);
      }, 1000)

  };

  useEffect(() => {
    if(props.action=='signout'){
      removeCookie('token')
      removeCookie('username')
      removeCookie('name')
      removeCookie('job_id')
      navigate("/signin");
    }
  })



  return (
    <div>
      <MsgBox config={isMsgBox} />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Typography
                        variant="h3"
                        noWrap
                        component="a"
                        href="/dashboard"
                        sx={{
                          mr: 2,
                          fontFamily: 'monospace',
                          fontWeight: 700,
                          letterSpacing: '.3rem',
                          color: '#1876D2',
                          textDecoration: 'none',
                        }}
                    >
                        <DataObjectIcon style={{fontSize:'45', marginRight: '-20px'}} /> BAS 
                    </Typography>
                    <Typography variant="h6" style={{marginBottom:'20px'}}>
                      Broadband Automation System
                    </Typography>
         
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              onChange={(e) => setForm({username:e.target.value})}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setForm({password:e.target.value})}
              autoComplete="current-password"
            />
            { /* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
                      /> */ }
             <MuiLab.LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth              
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </MuiLab.LoadingButton>
            
            { /* <Grid container>
              <Grid sx={{flexGrow: 1}}></Grid>
              <Grid item xs align="right">
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */ }
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </div>
  );
}
