import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import * as MuiLab from '@mui/lab';
import { MuiFileInput } from 'mui-file-input';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';
import Region from '../Assets/region.json'

const DrawerTerminateCustomerService = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const childref = useRef();
    const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [rfs_date,setRfs_date] = React.useState()
    const [fileKtp, setFileKtp] = React.useState(null)
    const [fileLoc, setFileLoc] = React.useState(null)
    const [products,setProducts] = React.useState([]);
    const [cluster,setCluster] = React.useState([]);
    const [region,setRegion] = React.useState(Region);
    const [selectedRegion, setSelectedRegion] = React.useState(16);
    const [regencies,setRegencies] = React.useState(Region[16].regencies);
    const [selectedRegency, setSelectedRegency] = React.useState(0);
    const [districts,setDistricts] = React.useState(Region[16].regencies[0].districts);
    const [selectedDistrict,setSelectedDistrict]= React.useState(0)
    const [villages,setVillages] = React.useState(Region[16].regencies[0].districts[0].villages)
    const [selectedVillage,setSelectedVillage] = React.useState(0)
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      submitForm: false,
      subscription_date: ''
      });
    var obj;

    useImperativeHandle(ref, () => ({
      openForm: (id,customer,taxo) => {
        setState({ isForm: true, id: id, customer: customer,taxo: taxo})
        setProducts(taxo.product)
        setCluster(taxo.cluster)
        console.log(taxo)
        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

  const { register, formState: { errors }, handleSubmit, setValue } = useForm()
  const onSubmit = async(data) => {
        loadingOn()
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization",cookies.token);

        const formdata = await new FormData(formRef.current);
        if(fileLoc){formdata.append("location_photo", fileLoc, fileLoc.name)}
        if(fileKtp){formdata.append("customer_id_card", fileKtp, fileKtp.name)}

/*
        let formObject = Object.fromEntries(formdata.entries());
        console.log('=====FORM REF Data : ' + cookies.token)
        console.log(formObject);*/
       
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };

        await fetch(props.config.hostApi + 'customers/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(obj.success){
              refreshParentCustomers()
              loadingOff()
            }else{
              setError(obj.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Add New Customer',
                msg: obj.message,
              })
            }
          })
          .then(()=>{
            console.log(obj)
          })
          
          
  }
 
  const loadingOn = () =>{
    console.log('Loading On')
    setLoading(true)
  }
  const loadingOff = () =>{
    console.log('LOading Off')
    setLoading(false)
  }
  const refreshParentCustomers = () => {
    props.refreshParentCustomers()
    setState({isForm:false})
  }
  
  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters sx={{bgcolor: "#FBDEDF", color:'#DC2625'}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Terminate Service &raquo; ({state.id})
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.NotInterested />}
                    loadingPosition="start"
                    > Terminate
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                  <input type="hidden" value={7} {...register("function", { required: true })} />
                          <Mui.Grid container>
                           
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.Grid item md={12} 
                                      padding={3}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center">
                                      <Mui.Avatar sx={{ bgcolor: "#FBDEDF", color:'#DC2625', width: 80, height:80 }}>
                                          <MuiIcon.NotInterested sx={{fontSize:'40px'}} />
                                      </Mui.Avatar>
                                  </Mui.Grid>
                                  <Mui.Grid
                                    justifyContent="center"
                                    align="center"
                                    p={'0px 3px'}
                                      >
                                      <Mui.Typography variant='h6' p={'0px 2px'}>Are you sure you want to terminate this service?</Mui.Typography>
                                      <Mui.Typography variant='subtitle1'>By termination service "{state.id}", all task assigned to that service will also be terminate.</Mui.Typography>
                                  </Mui.Grid>

                            </Mui.Grid>
                          
                          <Mui.Divider style={{margin:'20px 10px'}} />
                            
                              <Mui.Grid item xs={12} className="innerForm">
                                <Mui.Grid
                                      justifyContent="center"
                                      align="center"
                                      p={'0px 3px'}
                                        >
                                      <Mui.Typography variant='subtitle1'>The reason of service termination</Mui.Typography><br />
                                  </Mui.Grid>
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          multiline
                                          size="small"
                                          rows={6}
                                          label="Note"
                                          {...register("note")}
                                       />
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'product_id').length > 0 && <p className="error">{error.filter(error => error.path === 'product_id')[0].msg}</p>}
                              </Mui.Grid>
                              
                            
                      </Mui.Grid>
                  
            </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerTerminateCustomerService;
