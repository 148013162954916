import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"

import Header from '../../lib/Header';
import axios from 'axios';
import MsgBox from '../../lib/MsgBox';
import DrawerEditOlt from '../../drawer/DrawerEditOlt';
import DrawerDeleteOlt from '../../drawer/DrawerDeleteOlt';
import DrawerAddOlt from '../../drawer/DrawerAddOlt';



const Olt = (props) => {
  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [olt,setOlt] = React.useState([])
  const [bng,setBng] = React.useState([])
  const [loading,setLoading] = React.useState(false)

  const addRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();
  const formRef = useRef();
  const [taxo,setTaxo] = useState([]);
  const [page,setPage] = React.useState()

  const { register, setValue, handleSubmit,reset } = useForm()
  const onSubmit = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        perPage: data.perPage
      })
      getDevices()
  }
  const changePage = (event,value) => {
    setPage(value);
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    perPage: 10

  })

  const afterSave=()=>{
    getDevices()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Olt',
      msg: 'Saving new Olt successfully.'
    })
  }

  const afterDelete=()=>{
    getDevices()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete Olt',
      msg: 'Delete Olt successfully'
    })
  }
  const afterUpdate=()=>{
    getDevices()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Olt',
      msg: 'Update Olt successfully'
    })
  }
  const getTaxo = () => {
    const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 'taxo',
       {
        object : ["vendor","vendor_product_type","snmp_version","remote_protocol"]
       },
       { headers: header })
        .then(res => {

          console.log(res.data.data)
            setTaxo(res.data.data)
          })
        .catch((error) => {
          console.log(error)
        })
  }
  const getBNG = () => {
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'devices',
     {
      search: filter.search,
      perPage: filter.perPage,
      page: page,
      function:[9] // <= Filter Device by BNG
     },
     { headers: header })
      .then(res => {
          setBng(res.data.data)
        })
      .catch((error) => {
        /*this.setState({
            isMsgBox:{
              action: 'signout'
          }
        })*/
      })
  }
  const getDevices = () => {
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'devices',
     {
      search: filter.search,
      perPage: filter.perPage,
      page: page,
      function:[11] // <= Filter Device by Olt
     },
     { headers: header })
      .then(res => {
          setOlt(res.data.data)
          setPagination(res.data.pagination)
        })
      .catch((error) => {
        /*this.setState({
            isMsgBox:{
              action: 'signout'
          }
        })*/
      })
  }
  

  React.useEffect(() => {
    getTaxo()
    getDevices()
    getBNG()
  }, [filter,page]); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAddOlt ref={addRef} config={props.config} refreshOltSave={afterSave} />
      <DrawerEditOlt ref={editRef} config={props.config} refreshOltUpdate={afterUpdate} />
      <DrawerDeleteOlt ref={deleteRef} config={props.config} refreshOltDelete={afterDelete} />
      
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Olt ({pagination.totalRecords})
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <Mui.Chip 
              label="Add New Olt" 
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.Storage />}
              onClick={ ()=> {
                addRef.current.openForm(bng,taxo);
              }}
              />
              <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />
      </Mui.Toolbar>
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' {...register("object")} />
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={loading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
            ) : (
              <>
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>ID</Mui.TableCell>
                                <Mui.TableCell>Hostname</Mui.TableCell>
                                <Mui.TableCell>IP Address</Mui.TableCell>
                                <Mui.TableCell>User Access</Mui.TableCell>
                                <Mui.TableCell>Description</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { olt.map((_olt,key) => (
                          
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <Mui.TableCell component="th" scope="row">
                                    {_olt.id}
                                </Mui.TableCell>
                                <Mui.TableCell>{_olt.hostname}</Mui.TableCell>
                                <Mui.TableCell>{_olt.ip_address}</Mui.TableCell>
                                <Mui.TableCell>{_olt.user_access}</Mui.TableCell>
                                <Mui.TableCell>{_olt.desc}</Mui.TableCell>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                    <MuiJoy.MenuButton
                                      slots={{ root: MuiJoy.IconButton }}
                                      slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                    >
                                      <MuiIcon.MoreVert />
                                    </MuiJoy.MenuButton>
                                    <MuiJoy.Menu placement="bottom-end">

                                    
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                            editRef.current.openForm(_olt,bng,taxo);
                                        }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.Edit />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit
                                      </MuiJoy.MenuItem>
                                      
                                      <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                            deleteRef.current.openForm(_olt.id); 
                                          }}>
                                        <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                          <MuiIcon.NotInterested />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Delete
                                      </MuiJoy.MenuItem>
                                      
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                                      ))}
                        </Mui.TableBody>
                    </Mui.Table>
                </Mui.Grid>
                
                <Mui.Grid item xs={12} >
                    <Mui.Pagination 
                        sx={{ 
                          '& > .MuiPagination-ul': {
                            justifyContent: 'center',
                          },
                          p:2,
                        }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
                </Mui.Grid>
              </>
            )}
      </Mui.Grid>
    </div>
  );
}

export default Olt;
