import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { Transition } from 'react-transition-group';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/base/Snackbar';
import { SnackbarCloseReason } from '@mui/base/useSnackbar';


import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';

/*const action = (
  <React.Button color="secondary" size="small">
    lorem ipsum dolorem
  </React.Button>
);*/

export default function MsgBox(isMsgBox) {
  let navigate = useNavigate();
  const [exited, setExited] = React.useState(true);
  const [open, setOpen] = React.useState({
    open:isMsgBox.open,
    title:isMsgBox.title, 
    status:isMsgBox.status, 
    msg: isMsgBox.msg,
    button: isMsgBox.button,
    action: '',
  });
  const nodeRef = React.useRef(null);

  const handleClose = () => {
    setOpen({open:false})
  };

  const icon = (status) => {
    switch(status) {
      case 'success': 
        return (
          <MuiIcon.CheckCircleOutline
            sx={{
              color: status + '.main',
              flexShrink: 0,
              width: '40px',
              height: '30px',
            }}
          /> );
      
      case 'warning': 
        return (
          <MuiIcon.ErrorOutline
            sx={{
              color: status + '.main',
              flexShrink: 0,
              width: '40px',
              height: '30px',
            }}
          /> );

        case 'error': 
          return (
            <MuiIcon.HighlightOff
              sx={{
                color: status + '.main',
                flexShrink: 0,
                width: '40px',
                height: '30px',
              }}
            /> );

      default: 
        return null;
      
    }
  };
  const button = (status) => {
    switch(status) {
      case 1: 
        return (
          <div className="snackbar-button">
                  <Mui.Button onClick={handleClose}>No</Mui.Button>
                  <Mui.Button onClick={()=>{
                    isMsgBox.action('delete'); 
                    setOpen({open:false})}}>Yes </Mui.Button>
                </div> 
          );
      default: 
        return null;
      
    }
  }

  const handleOpen = () => {
    setOpen({open:true});
  };

  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };

  useEffect(() => {
    if(isMsgBox.config.open === true){
      setOpen({
        open:isMsgBox.config.open,
        title:isMsgBox.config.title, 
        status:isMsgBox.config.status, 
        msg: isMsgBox.config.msg,
        button: isMsgBox.config.button,
      })
      isMsgBox.config.open = false
    }
    switch(isMsgBox.config.action) { 
      case 'signout': {
          navigate("/signout");
         break; 
      } 
      case '404': {
        navigate("/404");
        break; 
      } 
   } 
   /*
    if(isMsgBox.config.action==='signout'){
      navigate("/signout");
    }*/
  }); 
  
  return (
    <div>
    <StyledSnackbar
        autoHideDuration={10000}
        open={open.open}
        onClose={handleClose}
        exited={exited}
      >
        <Transition
          timeout={{ enter: 400, exit: 400 }}
          in={open.open}
          appear
          unmountOnExit
          onEnter={handleOnEnter}
          onExited={handleOnExited}
          nodeRef={nodeRef}
        >
          {(status) => (
            <SnackbarContent
              style={{
                transform: positioningStyles[status],
                transition: 'transform 300ms ease',
              }}
              ref={nodeRef}
              //action={action}
            >
             { icon(open.status) }
              <div className="snackbar-message">
                <h3 className="snackbar-title">{open.title}</h3>
                <p className="snackbar-description">{open.msg}</p>
                {button(open.button)}
              </div>
             
              <MuiIcon.Close onClick={handleClose} className="snackbar-close-icon" />
            </SnackbarContent>
          )}
        </Transition>
      </StyledSnackbar>
    </div>
  );
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const blue = {
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const TriggerButton = styled('button')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
  `,
);

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  z-index: 5500;
  display: flex;
  top: 16px;
  left: 16px;
  max-width: 560px;
  min-width: 300px;
`;

const SnackbarContent = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: ${
    theme.palette.mode === 'dark'
      ? `0 2px 16px rgba(0,0,0, 0.5)`
      : `0 2px 16px rgba(0,0,0, 0.3)`
  };
  padding: 0.75rem;
  color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;

  & .snackbar-button {
    position:relative;
    max-width: 100%;
    text-align:right;
  }

  & .snackbar-message {
    flex: 1 1 0%;
    max-width: 100%;
  }

  & .snackbar-title {
    margin: 0;
    line-height: 1.5rem;
    margin-right: 0.5rem;
  }

  & .snackbar-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
  }

  & .snackbar-close-icon {
    cursor: pointer;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 4px;

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }
  }
  `,
);

const positioningStyles = {
  entering: 'translateX(0)',
  entered: 'translateX(0)',
  exiting: 'translateX(-500px)',
  exited: 'translateX(-500px)',
  unmounted: 'translateX(-500px)',
};