import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';

import { useForm, SubmitHandler } from "react-hook-form"
import axios from 'axios';
import MsgBox from '../lib/MsgBox';


const DrawerAddRadius = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));
      urlencoded.append("function", 7);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'devices/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            if(result.success){
              refreshParentDevices()
              loadingOff()
              reset()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Save Radius',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            console.log('disini')
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      radius: [],
      vendor:[],
      vendor_product_type:[],
      snmp_version:[],
      remote_protocol:[]
    });

    useImperativeHandle(ref, () => ({
      openForm: (taxo) => {
        console.log(taxo)
        setState({ 
          isForm: true,  
          vendor:taxo.vendor,
          vendor_product_type:taxo.vendor_product_type,
          snmp_version: taxo.snmp_version,
          remote_protocol: taxo.remote_protocol
         })
      }
   }));

    const loadingOn = () =>{
      setLoading(true)
    }
    const loadingOff = () =>{
      setLoading(false)
    }
    const refreshParentDevices = () => {
      props.refreshRadiusSave()
      setState({
        isForm:false,
        radius: [],
        vendor:[],
        vendor_product_type:[],
        snmp_version:[],
        remote_protocol:[]
      })
    }
  React.useEffect(() => {
    

  }); 

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Add New Radius
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <Mui.Button
                    variant="contained"
                    disabled={state.loading}
                    onClick={()=> setState({isForm:false,
                      radius: [],
                      vendor:[],
                      vendor_product_type:[],
                      snmp_version:[],
                      remote_protocol:[]})}    
                    sx={{ margin: '0px 3px' }}
                  >
                      <MuiIcon.Replay/> Back 
                  </Mui.Button>
                  <MuiLab.LoadingButton
                    type="submit"
                    loading={state.loading}
                    disabled={state.loading}
                    loadingPosition="start"
                    variant="contained"  
                    sx={{ margin: '0px 3px'}}
                    startIcon={<MuiIcon.Save />} 
                    > Save
                  </MuiLab.LoadingButton>
            </Mui.Toolbar>
            <Mui.DialogContent>
                    <Mui.Grid container>
                        <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                            <span>* Require Field</span>
                        </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.TextField label="*Hostname" size="small" variant="outlined" className="inputField" {...register("hostname")} />
                              {error.filter(error => error.path === 'hostname').length > 0 && <p className="error">{error.filter(error => error.path === 'hostname')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.TextField label="*IP Address " size="small" variant="outlined" className="inputField" {...register("ip_address")} />
                              {error.filter(error => error.path === 'ip_address').length > 0 && <p className="error">{error.filter(error => error.path === 'ip_address')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Device Brand"
                                    {...register("vendor")} 
                                >
                                    {state.vendor.map((vendor,key) => (
                                    <Mui.MenuItem key={key} value={vendor.id}>{vendor.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'vendor').length > 0 && <p className="error">{error.filter(error => error.path === 'vendor')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Device Type"
                                    {...register("vendor_product_type")} 
                                >
                                    {state.vendor_product_type.map((type,key) => (
                                    <Mui.MenuItem key={key} value={type.id}>{type.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'vendor_product_type').length > 0 && <p className="error">{error.filter(error => error.path === 'vendor_product_type')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.TextField 
                                  label="Description" 
                                  multiline
                                  size="small"
                                  rows={6}
                                  variant="outlined" 
                                  className="inputField" 
                                  {...register("desc")} />
                                  {error.filter(error => error.path === 'desc').length > 0 && <p className="error">{error.filter(error => error.path === 'desc')[0].msg}</p>}
                          </Mui.Grid>
                          <br />
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.Typography variant="h6" style={{marginLeft:'20px', marginTop:'20px'}}>Remote Access</Mui.Typography>
                              <Mui.Divider variant="middle"/>
                          
                          </Mui.Grid>
                          
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="Username " size="small" variant="outlined" className="inputField" {...register("user_access")} />
                              {error.filter(error => error.path === 'user_access').length > 0 && <p className="error">{error.filter(error => error.path === 'user_access')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="Password" size="small" variant="outlined" className="inputField" {...register("password_access")} />
                              {error.filter(error => error.path === 'password_access').length > 0 && <p className="error">{error.filter(error => error.path === 'password_access')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Remote Protocol"
                                    {...register("remote_protocol")} 
                                >
                                    {state.remote_protocol.map((remoteProtocol,key) => (
                                    <Mui.MenuItem key={key} value={remoteProtocol.value}>{remoteProtocol.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'remote_protocol').length > 0 && <p className="error">{error.filter(error => error.path === 'remote_protocol')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="Remote Data (ex:port/url)" size="small" variant="outlined" className="inputField" {...register("remote_data")} />
                              {error.filter(error => error.path === 'remote_data').length > 0 && <p className="error">{error.filter(error => error.path === 'remote_data')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                <Mui.InputLabel id="snmp-version" sx={{mt:-1}}>SNMP Version</Mui.InputLabel>
                                    <Mui.Select
                                    labelId="snmp-version"
                                    id="snmp-version-helper"
                                    label="Snmp Version"
                                    size="small"
                                    {...register("snmp_version")}
                                    >
                                        {state.snmp_version.map((snmpver,key) => (
                                        <Mui.MenuItem key={key} value={snmpver.id}>{snmpver.value}</Mui.MenuItem>
                                        ))}
                                    </Mui.Select>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'snmp_version').length > 0 && <p className="error">{error.filter(error => error.path === 'snmp_version')[0].msg}</p>}
                          </Mui.Grid>

                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="SNMP Community" size="small" variant="outlined" className="inputField" {...register("snmp_community")} />
                              {error.filter(error => error.path === 'snmp_community').length > 0 && <p className="error">{error.filter(error => error.path === 'snmp_community')[0].msg}</p>}
                          </Mui.Grid>
                    </Mui.Grid>
                
            </Mui.DialogContent>
          </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddRadius;
