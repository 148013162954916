import * as React from 'react';
import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';
import { Button } from '@mui/material';

export default function Test() {
  const [checked, setChecked] = React.useState([true, false]);
  const [checkAll, setCheckAll] = React.useState(false)
  const [check, setCheck] = React.useState([true, false,true, false]);

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };

  const handleChangeA = (event,id) => {
   console.log(event.target.checked)
   if(event.target.checked){
      console.log("ok:" +id)
   }else{
      console.log("not ok")
   }
   //setChecked([checked[0], event.target.checked]);
 };

  const checkAllFalse =()=>{
      const checkObject = [];
      for(var i=0; i<check.length; i++){
         checkObject[i]=false
      }
      setCheck(checkObject)
      setCheckAll(false)
  }
  const checkAllTrue =()=>{
      const checkObject = [];
      for(var i=0; i<check.length; i++){
         checkObject[i]=true
      }
      setCheck(checkObject)
      setCheckAll(true)
  }
  const checkAllAction = () => {
      console.log(checkAll)
      if(checkAll==false){
         checkAllTrue()
      }else{
         checkAllFalse()
      }
      console.log(check.length)
      console.log(check)
 };
 React.useEffect(()=>{
   console.log(check)
 })

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3, gap: 1, mt: 1 }}>
      <Checkbox checked={checked[0]} onChange={handleChange2} label="Child 1" />
      <Checkbox checked={checked[1]} onChange={handleChange3} label="Child 2" />
      <Checkbox checked={checked[2]} onChange={handleChange2} label="Child 3" />
      <Checkbox checked={checked[3]} onChange={(event)=>{
         const checkObject = [];
         for(var i=0; i<check.length; i++){
            if(i==3){
               checkObject[i]=event.target.checked
            }else{
               checkObject[i]=check[i]
            }
         }
         console.log(checkObject)
         setCheck(checkObject)
      }} label="Child 4" />
    </Box>
  );
   
  const options = [];
  for (let i = 0; i < check.length; i++) {
      //options.push(<Checkbox checked={check[i]} label={"Child "+ i} onChange={handleChange(i)} />);
   }
  return (
    <div>
      <Button onClick={checkAllAction}>Test</Button>
      <Checkbox
        label="Parent"
        checked={checked[0] && checked[1] && checked[2] && checked[3]}
        indeterminate={checked[0] !== checked[1]}
        onChange={handleChange1}
      
        />
        {children}
      {/*options*/}

    </div>
  );
}