import React,{ useRef,useState } from 'react';
import * as Mui  from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function GetStatus(props) {
  
  const statusCustomer = (_status,_statusInfo,_response=0) => {
    switch(_status) { 
      case 0: {
        //On Request
        console.log(_response)
        if(_response==0){
          return( 
            <Mui.Chip sx={{minWidth:'90px'}}
            label={_statusInfo}  
              color="warning"
            />
            ) 
           break;
        }else{
          return( 
            <Mui.Chip sx={{minWidth:'90px'}} 
              label='Responded' 
              color="primary"
            />)
           break;
          
        }
         
      } 
      case 1: {
        //Active
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
            label={_statusInfo} 
            color="success"
          /> 
          )
         break; 
      } 
      case 2: { 
        //Suspended
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="warning"
          />
          ) 
         break; 
      } 
      case 3: { 
        //Terminated
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="error"
          />
          ) 
         break; 
      } 
      case 4: { 
        //Reject
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="error"
          />
          ) 
         break; 
      } 
      default: { 
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={_statusInfo}  
            color="default"
          />
          ) 
         break; 
      } 
   } 
   
  }
  
  return statusCustomer(props.status, props.statusInfo,props.response);
}
