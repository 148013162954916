import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import * as MuiLab from '@mui/lab';
import { MuiFileInput } from 'mui-file-input';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';
import Region from '../Assets/region.json'

const DrawerDeleteTaxonomy = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      id:''
      });

    useImperativeHandle(ref, () => ({
      openForm: (id) => {
        setState({ isForm: true, id: id})

        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

  const { register, formState: { errors }, handleSubmit, setValue } = useForm()
  const onSubmit = (data) => {
    console.log('disiniii')
    loadingOn()
        
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams(new FormData(formRef.current));

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };


    fetch(props.config.hostApi + 'taxo/'+state.id, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          if(result.success){
            refreshParent()
            loadingOff()
          }else{
            setError(result.errors)
            loadingOff()
            setisMsgBox({
              open: true,
              status: 'error',
              title:'Delete Taxonomy',
              msg: result.message,
            })
          }
        })
        .then(()=>{
          loadingOff()
        })
        
  }
 
  const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const refreshParent = () => {
    props.refreshParentDelete()
    setState({isForm:false,id:''})
  }
  
  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters sx={{bgcolor: "#FBDEDF", color:'#DC2625'}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Delete Taxonomy &raquo; ({state.id})
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.NotInterested />}
                    loadingPosition="start"
                    > Delete
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                  <input type="hidden" value={state.id} {...register("id", { required: true })} />
                          <Mui.Grid container>
                           
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.Grid item md={12} 
                                      padding={3}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center">
                                      <Mui.Avatar sx={{ bgcolor: "#FBDEDF", color:'#DC2625', width: 80, height:80 }}>
                                          <MuiIcon.NotInterested sx={{fontSize:'40px'}} />
                                      </Mui.Avatar>
                                  </Mui.Grid>
                                  <Mui.Grid
                                    justifyContent="center"
                                    align="center"
                                    p={'0px 3px'}
                                      >
                                      <Mui.Typography variant='h6' p={'0px 2px'}>Are you sure you want to Delete this Taxonomy?</Mui.Typography>
                                      <Mui.Typography variant='subtitle1'>By deleting Taxonomy "{state.id}", all task assigned to that Taxonomy will also be delete.</Mui.Typography>
                                  </Mui.Grid>

                            </Mui.Grid>
                          
                          
                              
                            
                      </Mui.Grid>
                  
            </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerDeleteTaxonomy;
