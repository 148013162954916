import React,{ useRef,useState } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"


import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import '../lib/style.css'
import Header from '../lib/Header';
import DrawerAddCustomer from '../drawer/DrawerAddCustomer';
import DrawerEditCustomerService from '../drawer/DrawerEditCustomerService';
import DrawerEditCustomer from '../drawer/DrawerEditCustomer';
import DrawerAddCustomerService from '../drawer/DrawerAddCustomerService';
import DrawerTerminateCustomerService from '../drawer/DrawerTerminateCustomerService';
import DrawerChpkgServiceOrder from '../drawer/DrawerChpkgServiceOrder';

import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import GetStatus from '../lib/GetStatus';
import Region from '../Assets/region.json'
import { border, maxWidth, width } from '@mui/system';
import { left } from '@popperjs/core';

const Customers = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  let { act } = useParams();
  const addRef = useRef();
  const editRef = useRef();
  const editServiceRef = useRef();
  const serviceRef = useRef();
  const terminateRef = useRef();
  const chpkgRef = useRef();
  const formRef = useRef();
  const [state, setState] = useState({
    isAddForm: true,
    isEditForm: false,
    isServiceForm: false,
    loading: false,
    _customers: []
  })
  const [start_subs_date,set_start_subs_date] = React.useState()
  const [end_subs_date,set_end_subs_date] = React.useState()
  const [keyword,setKeyword] = React.useState()
  const [district,setDistrict] = React.useState()
  const [cluster_id,setCluster_id] = React.useState()
  const [product,setProduct] = React.useState()
  const [status,setStatus] = React.useState()
  const [perpage,setPerpage] = React.useState()
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [taxo,setTaxo] = useState([]);
  const { register, setValue, handleSubmit,reset } = useForm()
  var obj;

  const [page,setPage] = React.useState()

  const changePage = (event,value) => {
    setPage(value);
    getCustomers()
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    cluster_id: '',
    product_id:'',
    subscription_date_start: '',
    subscription_date_end: '',
    perPage: 10

  })

  const onSubmit = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        cluster_id: data.cluster_id,
        product_id: data.product_id,
        subscription_date_start: data.subscription_date_start,
        subscription_date_end: data.subscription_date_end,
        perPage: data.perPage
      })
      getCustomers()
  }

  const afterSave=()=>{
    getCustomers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Customer',
      msg: 'Saving service new customer successfully. Please check on service order.'
    })
  }
  const afterSaveService=()=>{
    getCustomers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Customer',
      msg: 'Saving new Service Customer successfully'
    })
  }
  const afterUpdate=()=>{
    getCustomers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Customer',
      msg: 'Update Customer successfully'
    })
  }
  const afterServiceUpdate=()=>{
    getCustomers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Service Customer',
      msg: 'Update Service Customer successfully'
    })
  }
  const deleteConfirm = (id,hostname) => {
      setisMsgBox({
        open: true,
        status: 'warning',
        title:'Delete Customer',
        msg: 'Are you sure delete Customer Server : '+hostname+'?',
        button: 1,
        action:'delete',
        idTarget:id
      })
  }
  const afterdelete=()=>{
    getCustomers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete Customer',
      msg: 'Delete Customer Server successfully'
    })
  }
  const afterChpkgService=()=>{
    getCustomers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Change Package Service',
      msg: 'Order Change Package Service successfully'
    })
  }
  const getTaxo = () => {
    const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 'taxo',
       {
        object : ["function","vendor",'snmp_version',"cluster","product","ont"]
       },
       { headers: header })
        .then(res => {
            setTaxo(res.data.data)
          })
        .catch((error) => {
          console.log(error)
        })
  }
  const getCustomers = () => {
    setContentLoading(true)
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'customers',{
        search: filter.search,
        cluster_id: filter.cluster_id,
        product_id: filter.product_id,
        subscription_date_start: filter.subscription_date_start,
        subscription_date_end: filter.subscription_date_end,
        perPage: filter.perPage,
        page: page
     },
     { headers: header })
      .then(res => {
        console.log(res.data.data)
          setContentLoading(false)
          setState({
            _customers : res.data.data
          })
          setPagination(res.data.pagination)
          
      })
      .catch((error) => {
        setContentLoading(false)
        setState({
          isMsgBox:{
            action: 'signout'
          }
        })
      })
  };
  React.useEffect(() => {
    getTaxo()
    getCustomers();
    if(act==='add'){ addRef.current.openForm(); }
  }, [filter,page]); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAddCustomer ref={addRef} config={props.config} refreshParentCustomers={afterSave} />
      <DrawerEditCustomerService ref={editServiceRef} config={props.config} refreshParentCustomers={afterServiceUpdate} />
      <DrawerEditCustomer ref={editRef} config={props.config} refreshParentCustomersUpdate={afterUpdate} refreshParentCustomersDelete={afterdelete} />
      <DrawerAddCustomerService ref={serviceRef} config={props.config} refreshParentCustomers={afterSaveService} />
      <DrawerTerminateCustomerService ref={terminateRef} config={props.config} refreshParentCustomers={afterSaveService} />
      <DrawerChpkgServiceOrder ref={chpkgRef} config={props.config} refreshParentChpkg={afterChpkgService} />
      <Header config={props.config} />
      <Mui.Grid className='topBar' container style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
        <Mui.Grid item md={12}>
          <Mui.Toolbar disableGutters>
              <Mui.Breadcrumbs aria-label="breadcrumb">
                  <Mui.Link
                    underline="none"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                  >
                    <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                  </Mui.Link>
                  
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    Service Customer ({pagination.totalRecords} Services)
                  </Mui.Typography>
              </Mui.Breadcrumbs>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              <Mui.Chip 
                label="Add New Customer" 
                variant="outlined" 
                onClick={ 
                  ()=> {
                    addRef.current.openForm(taxo);
                  }}
                style={{margin:'0px 3px', padding: '5px'}} 
                icon={<MuiIcon.PersonAdd />}
              />
              <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                //setVariant('soft');
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />
          </Mui.Toolbar>
        </Mui.Grid>
      </Mui.Grid>

      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Grid container 
                style={{
                backgroundColor:'#f8f8f8',
                padding:'10px 20px 10px 20px'
              }}>
                    <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                        <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
                    </Mui.Box>
                    <Mui.Box sx={{minWidth:'120px',margin: '5px'}}>
                        <Mui.FormControl size="small" className="inputField">
                            <input type='hidden' {...register("cluster_id")} />
                            <Mui.TextField
                                select
                                size="small"
                                label="Cluster"
                                
                                onChange={(event)=>(
                                  event.target.value != 0 ? setValue('cluster_id',[event.target.value]) : setValue('cluster_id',[])
                                  
                                )}
                            >
                              <Mui.MenuItem key={-1} value={0}>All Clusters</Mui.MenuItem>
                              {taxo.cluster.map((cluster,key) => (
                                <Mui.MenuItem key={key} value={cluster.id}>{cluster.value}</Mui.MenuItem>
                                ))}
                                
                            </Mui.TextField>
                        </Mui.FormControl>
                    </Mui.Box>
                    <Mui.Box sx={{minWidth:'120px',margin: '5px'}}>
                        <Mui.FormControl size="small" className="inputField">
                            <input type='hidden' {...register("product_id")} />
                            <Mui.TextField
                                select
                                size="small"
                                label="Product"
                                onChange={(event)=>(
                                  event.target.value != 0 ? setValue('product_id',[event.target.value]) : setValue('product_id',[])
                                  
                                )}
                            >
                              <Mui.MenuItem key={-1} value={0}>All Products</Mui.MenuItem>
                              {taxo.product.map((product,key) => (
                                <Mui.MenuItem key={key} value={product.id}>{product.value}</Mui.MenuItem>
                                ))}
                                
                            </Mui.TextField>
                        </Mui.FormControl>
                    </Mui.Box>
                    <Mui.Box sx={{margin: '5px'}}>
                      <div style={{width:'340px',float:'left'}}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                              <input type="hidden" value={start_subs_date} {...register("subscription_date_start")} />
                              <DatePicker label="Start Subs Date" 
                                      sx={{ maxWidth: "165px " }}
                                      slotProps={{ textField: { size: 'small', border:'1px solid red' } }}  
                                      format="YYYY-MM-DD" 
                                      onChange={(event)=>{
                                          if(event == null){
                                            setValue('subscription_date_start',null)
                                            set_start_subs_date( null)
                                          }else{
                                            setValue('subscription_date_start',new Date(event).toLocaleDateString("sv-SE"))
                                            set_start_subs_date( new Date(event).toLocaleDateString("sv-SE"))
                                          }
                                      }} />
                          </LocalizationProvider> &nbsp; 
                          <LocalizationProvider dateAdapter={AdapterDayjs} >                          
                              <input type="hidden" value={end_subs_date} {...register("subscription_date_end")} />
                              <DatePicker label="End Subs Date" 
                                      sx={{ maxWidth: "165px " }}
                                        slotProps={{ textField: { size: 'small' } }}  
                                        format="YYYY-MM-DD" 
                                        onChange={(event)=>{
                                          if(event == null){
                                            setValue('subscription_date_end',null)
                                            set_end_subs_date( null)
                                          }else{
                                            setValue('subscription_date_end',new Date(event).toLocaleDateString("sv-SE"))
                                            set_end_subs_date( new Date(event).toLocaleDateString("sv-SE"))
                                          }
                                        }} />
                          </LocalizationProvider>
                      </div>
                      <div style={{width:'110px',float:'left', marginLeft:'7px'}}>
                          <Mui.FormControl size="small" className="inputField">
                                <Mui.TextField
                                    select
                                    size="small"
                                    label="PerPage"
                                    {...register("perPage")}
                                >
                                <Mui.MenuItem key={0} value={2}>2 Orders</Mui.MenuItem>
                                    <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                                    <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                                    <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                                    <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                                </Mui.TextField>
                            </Mui.FormControl>
                      </div>
                    </Mui.Box>
                    <Mui.Box sx={{margin: '7px'}}>
                          <MuiJoy.Button
                            type="submit"
                            variant="solid" 
                            sx={{ margin: '0px 3px'}}
                            startDecorator={<MuiIcon.NavigateNext />}
                            loadingPosition="start"
                            > Show
                          </MuiJoy.Button>
                    </Mui.Box>
            </Mui.Grid>
        </form>
        ):('')}
      
      <Mui.Grid container></Mui.Grid>
      <Mui.Grid container spacing={2} style={{padding:'20px'}}>
          {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
          ) : (
            <>
              <Mui.Grid item lg={12}>
                  <Mui.Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <Mui.TableHead>
                              <Mui.TableRow>
                                  <Mui.Hidden lgDown><Mui.TableCell>SID</Mui.TableCell></Mui.Hidden>
                                  <Mui.Hidden lgDown><Mui.TableCell>CID</Mui.TableCell></Mui.Hidden>
                                  <Mui.TableCell style={{minWidth:"280px"}}>Customer Name</Mui.TableCell>
                                  <Mui.Hidden mdDown><Mui.TableCell>Cluster</Mui.TableCell></Mui.Hidden>
                                  <Mui.Hidden mdDown><Mui.TableCell align="center">Subscription Date</Mui.TableCell></Mui.Hidden>
                                  <Mui.Hidden mdDown><Mui.TableCell align="center">Product</Mui.TableCell></Mui.Hidden>
                                  <Mui.Hidden mdDown><Mui.TableCell align="center">Status</Mui.TableCell></Mui.Hidden>
                                  <Mui.TableCell>&nbsp;</Mui.TableCell>
                              </Mui.TableRow>
                          </Mui.TableHead>
                          <Mui.TableBody>
                          {state._customers?.map((customer,key) => (
                              <Mui.TableRow
                              key={key}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <Mui.Hidden lgDown><Mui.TableCell component="th" scope="row">{customer.id}</Mui.TableCell></Mui.Hidden>
                                <Mui.Hidden lgDown><Mui.TableCell component="th" scope="row">{customer.customer_id}</Mui.TableCell></Mui.Hidden>
                                <Mui.TableCell>{customer.name}</Mui.TableCell>
                                <Mui.TableCell>{customer.cluster_name}</Mui.TableCell>
                                <Mui.Hidden mdDown><Mui.TableCell align="center">{customer.subscription_date}</Mui.TableCell></Mui.Hidden>
                                <Mui.Hidden mdDown><Mui.TableCell align="center">{customer.product_name}</Mui.TableCell></Mui.Hidden>
                                <Mui.Hidden mdDown><Mui.TableCell align="center"><GetStatus status={customer['status']} statusInfo={customer['status_info']} /></Mui.TableCell></Mui.Hidden>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                      { customer.status != 3 &&
                                        <MuiJoy.MenuButton
                                          slots={{ root: MuiJoy.IconButton }}
                                          slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                        >
                                          <MuiIcon.MoreVert />
                                        </MuiJoy.MenuButton>
                                      }
                                    <MuiJoy.Menu placement="bottom-end">
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                        editRef.current.openForm(customer.id,customer,taxo); }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.Person />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit Customer
                                      </MuiJoy.MenuItem>
                                      { /*<MuiJoy.MenuItem onClick={ ()=> {
                                        editServiceRef.current.openForm(customer.id,customer,taxo); }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.BookmarkAdded />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit Service
                                      </MuiJoy.MenuItem>*/ }
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                        chpkgRef.current.openForm(customer.id,customer,taxo); }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.ChangeCircle />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Change Package
                                      </MuiJoy.MenuItem>
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                        serviceRef.current.openForm(customer.id,customer,taxo); }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.BookmarkAdd />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Add New Service
                                      </MuiJoy.MenuItem>
                                      <MuiJoy.MenuItem variant="soft" color="danger" onClick={ ()=> {terminateRef.current.openForm(customer.id,customer,taxo); }}>
                                        <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                          <MuiIcon.NotInterested />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Terminate
                                      </MuiJoy.MenuItem>
                                      { /*<Mui.Link href={"/serviceorder/"+customer.id} style={{textDecoration:'none', color:'#000000'}}>
                                        <MuiJoy.MenuItem variant="soft" >
                                          <MuiJoy.ListItemDecorator>
                                            <MuiIcon.ListAlt />
                                          </MuiJoy.ListItemDecorator>{' '}
                                          Service Order
                                        </MuiJoy.MenuItem>
                                      </Mui.Link> */ }
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                              </Mui.TableRow>
                              
                                  ))}
                          </Mui.TableBody>
                      </Mui.Table>
              </Mui.Grid>
              <Mui.Grid item xs={12} >
              <Mui.Pagination 
                  sx={{ 
                    '& > .MuiPagination-ul': {
                      justifyContent: 'center',
                    },
                    p:2,
                  }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
              </Mui.Grid>
            </>
          )}
      </Mui.Grid>
    </div>
  );
}

export default Customers;
