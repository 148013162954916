import React,{ useRef,useState } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"

import Header from '../lib/Header';
import MsgBox from '../lib/MsgBox';
import DrawerAddTemplate from '../drawer/DrawerAddTemplate';
import DrawerEditTemplate from '../drawer/DrawerEditTemplate';
import DrawerDeleteTemplate from '../drawer/DrawerDeleteTemplate';


const BillingTemplates = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [templates,setTemplates] = React.useState([])
  const [loading,setLoading] = React.useState(false)

  const addRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();
  const formRef = useRef();
  const [error,setError] = useState([]);
  const [page,setPage] = React.useState()
  const { register, setValue, handleSubmit,reset } = useForm()
  const onSubmit = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        device_id: data.device_id,
        perPage: data.perPage
        
      })
      getTemplates()
  }
  const changePage = (event,value) => {
    setPage(value);
    getTemplates()
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    perPage: 10

  })

  const afterSave=()=>{
    getTemplates()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Template',
      msg: 'Saving new Template successfully.'
    })
  }

  const afterDelete=()=>{
    getTemplates()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete Template',
      msg: 'Delete Template successfully'
    })
  }
  const afterUpdate=()=>{
    getTemplates()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Template',
      msg: 'Update Template successfully'
    })
  }
  const getTemplates = () => {
    setContentLoading(true)
    const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));
      urlencoded.append("search",filter.search);
      urlencoded.append("perPage", filter.perPage);
      urlencoded.append("page", page);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'invoice/template', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              console.log(result)
              setTemplates(result.data)
              setPagination(result.pagination)
              setContentLoading(false)
            }else{
              setError(result.errors)
              setContentLoading(false)
            }
          })
          .then((error)=>{
            console.log(error)
            setContentLoading(false)
          })
  }

  React.useEffect(() => {
    getTemplates();
  }, [filter,page]); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAddTemplate ref={addRef} config={props.config} refreshSave={afterSave} />
      <DrawerEditTemplate ref={editRef} config={props.config} refreshUpdate={afterUpdate} />
      <DrawerDeleteTemplate ref={deleteRef} config={props.config} refreshDelete={afterDelete} />
      
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Billing / Templates ({pagination.totalRecords})
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <Mui.Chip 
              label="Add New Template" 
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.Pix />}
              onClick={ ()=> {
                addRef.current.openForm();
              }}
              />
              <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />
      </Mui.Toolbar>
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' {...register("object")} />
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={loading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
            ) : (
              <>
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>ID</Mui.TableCell>
                                <Mui.TableCell>Template Name</Mui.TableCell>
                                <Mui.TableCell>Cut Off Date</Mui.TableCell>
                                <Mui.TableCell>Due Date</Mui.TableCell>
                                <Mui.TableCell>Duration</Mui.TableCell>
                                <Mui.TableCell>Recurring</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { templates.map((template,key) => (
                          
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <Mui.TableCell component="th" scope="row">
                                    {template.id}
                                </Mui.TableCell>
                                <Mui.TableCell>{template.bill_name}</Mui.TableCell>
                                <Mui.TableCell>{template.cut_off}</Mui.TableCell>
                                <Mui.TableCell>{template.due_date}</Mui.TableCell>
                                <Mui.TableCell>{template.period}</Mui.TableCell>
                                <Mui.TableCell>{template.recurring_name}</Mui.TableCell>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                    <MuiJoy.MenuButton
                                      slots={{ root: MuiJoy.IconButton }}
                                      slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                    >
                                      <MuiIcon.MoreVert />
                                    </MuiJoy.MenuButton>
                                    <MuiJoy.Menu placement="bottom-end">

                                    
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                            editRef.current.openForm(template);
                                        }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.Edit />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit
                                      </MuiJoy.MenuItem>
                                      
                                      <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                            //deleteRef.current.openForm(template.id); 
                                          }}>
                                        <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                          <MuiIcon.NotInterested />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Delete
                                      </MuiJoy.MenuItem>
                                      
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                                        )) }
                        </Mui.TableBody>
                    </Mui.Table>
                </Mui.Grid>
                
                <Mui.Grid item xs={12} >
                    <Mui.Pagination 
                        sx={{ 
                          '& > .MuiPagination-ul': {
                            justifyContent: 'center',
                          },
                          p:2,
                        }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
                </Mui.Grid>
              </>
            )}
      </Mui.Grid>
    </div>
  );
}

export default BillingTemplates;
