import React from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import Header from '../lib/Header';

const Page404 = (props) => {
  const [cookies] = useCookies(['bas_']);
  return (
    <div>
      <Header config={props.config} />
      <Mui.Grid className='topBar' container spacing={2}>
        <Mui.Grid item xs={12} style={{margin:'20px'}}>
          <h1>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>
        </Mui.Grid>
    </Mui.Grid>
    </div>
  );
}

export default Page404;



