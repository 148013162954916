import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';

import { MuiFileInput } from 'mui-file-input';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm, SubmitHandler } from "react-hook-form"
import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import Region from '../Assets/region.json'
import { getValue } from "@testing-library/user-event/dist/utils";


const DrawerEditCustomer = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const childref = useRef();
     const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [waitChangeOnt,setwaitChangeONT] =React.useState(false)
    const [waitResetPPPOE,setwaitResetPPPOE] =React.useState(false)
    const [error,setError] = React.useState([]);
    const [alertPPPOE,setAlertPPPOE] = React.useState('none')
    const [alertPPPOE1,setAlertPPPOE1] = React.useState('none')
    const [alertChONT,setAlertChONT] = React.useState('none')
    const [alertChONT1,setAlertChONT1] = React.useState('none')
    const [fileKtp, setFileKtp] = React.useState(null)
    const [urlFile,setUrlFile] = React.useState()
    const [urlLoc,setUrlLoc] = React.useState()
    const [urlKtp,setUrlKtp] = React.useState()
    const [fileLoc, setFileLoc] = React.useState(null)
    const [boxModal,setBoxModal] = React.useState(false)
    const [products,setProducts] = React.useState([]);
    const [ont,setOnt] = React.useState([]);
    const [selectedProduct,setSelectedProduct] = React.useState();
    const [cluster,setCluster] = React.useState([]);
    const [selectedCluster, setSelectedCluster] = React.useState()
    const [region,setRegion] = React.useState(Region);
    const [selectedRegion, setSelectedRegion] = React.useState(16);
    const [regencies,setRegencies] = React.useState(Region[16].regencies);
    const [selectedRegency, setSelectedRegency] = React.useState(0);
    const [districts,setDistricts] = React.useState(Region[16].regencies[0].districts);
    const [selectedDistrict,setSelectedDistrict]= React.useState(0)
    const [villages,setVillages] = React.useState(Region[16].regencies[0].districts[0].villages)
    const [selectedVillage,setSelectedVillage] = React.useState(0)
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      submitForm: false,
      subscription_date: '',
      taxo: []
      });
    var obj;


    useImperativeHandle(ref, () => ({
      openForm: (id,customer,taxo) => {
        setState({ isForm: true, id: id, customer: customer,taxo: taxo})
        setProducts(taxo.product)
        setCluster(taxo.cluster)
        setOnt(taxo.ont)
        setFileKtp(null)
        setFileLoc(null)
        setUrlKtp(customer.customer_id_card)
        setUrlLoc(customer.location_photo)
        setSelectedProduct(customer.product_id)
        setSelectedCluster(customer.cluster_id)
        setValue('id',id)
        setValue('customer_id',customer.customer_id)
        setValue('service_address',customer.service_address)
        setValue('location_coordinate',customer.location_coordinate)
        setValue('name', customer.name)
        setValue('customer_id_number',customer.customer_id_number)
        setValue('customer_id', customer.customer_id)
        setValue('alias', customer.alias)
        setValue('address', customer.address)
        setValue('pic', customer.pic)
        setValue('phone', customer.phone)
        setValue('email', customer.email)
        setValue('sn_onu',customer.sn_onu)
        setValue('pppoe_user',customer.pppoe_user)
        setValue('pppoe_pass',customer.pppoe_pass)

        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      delRadius: (id) => {
        childref.current.deleteConfirm(id);
      }
   }));

   const { register, formState: { errors }, handleSubmit, reset,getValues, setValue } = useForm()
  const onSubmit = (data) => {
        loadingOn()
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization",cookies.token);

        const formdata =  new FormData(formRef.current);
        if(fileLoc){formdata.append("location_photo", fileLoc, fileLoc.name)}
        if(fileKtp){formdata.append("customer_id_card", fileKtp, fileKtp.name)}


        //let formObject = Object.fromEntries(formdata.entries());
        //console.log('=====FORM REF Data : ' + cookies.token)
        //console.log(formObject);


        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };

       fetch(props.config.hostApi + 'customers/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(obj.success){
              refreshParentCustomersUpdate()
              loadingOff()
            }else{
              setError(obj.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Update Customer',
                msg: obj.message,
              })
            }
          })
          .then(()=>{
            console.log(obj)
          })
          
          
  }

  

  const resetPassPPPOE = (id) => {
    setwaitResetPPPOE(true)
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

   fetch(props.config.hostApi + 'customers/newpppoe/'+id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        obj = result
        if(obj.success){
          props.refreshParentCustomersUpdate()
          setValue('pppoe_pass',result.data.new_pppoe_pass)
          setAlertPPPOE('none')
          setAlertPPPOE1('block')
          setwaitResetPPPOE(false)
        }else{
          setError(obj.errors)
          setwaitResetPPPOE(false)
          setisMsgBox({
            open: true,
            status: 'error',
            title:'Reset Password PPPOE Customer',
            msg: obj.message,
          })
        }
      })
      .then(()=>{
        console.log(obj)
        setwaitResetPPPOE(false)
      })
      
      
}
  const changeONT = (id,ont_type,sn) => {

    setwaitChangeONT(true)
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);

    const urlencoded = new URLSearchParams(new FormData());
    urlencoded.append("id", id)
    urlencoded.append("ont_type", ont_type)
    urlencoded.append("sn_onu", sn)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    console.log(requestOptions)
  fetch(props.config.hostApi + '/customers/change-sn', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        obj = result
        if(obj.success){
          props.refreshParentCustomersUpdate()
          setAlertChONT('none')
          setAlertChONT1('block')
          setwaitChangeONT(false)
        }else{
          setError(obj.errors)
          setwaitChangeONT(false)
          setisMsgBox({
            open: true,
            status: 'error',
            title:'Change ONT Customer',
            msg: obj.message,
          })
        }
      })
      .then(()=>{
        console.log(obj)
        setwaitChangeONT(false)
      })
      
      
  }

  const loadingOn = () =>{
    console.log('Loading On')
    setLoading(true)
  }
  const loadingOff = () =>{
    console.log('LOading Off')
    setLoading(false)
  }
  const refreshParentCustomersUpdate = () => {
    props.refreshParentCustomersUpdate()
    setState({isForm:false})
  }

  const boxModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
    <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} /*onClose={ ()=>{setState({isForm:false})}}*/ PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
          <Mui.Modal
            open={boxModal}
            onClose={()=>{setBoxModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Mui.Box sx={boxModalStyle}>
              <img src={urlFile} />
            </Mui.Box>
          </Mui.Modal>  
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{backgroundColor:"#eeeeee"}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Edit Customer &raquo;  ( {state.id}  )
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.Save />}
                    loadingPosition="start"
                    > Update
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                      <input type="hidden"  {...register("id", { required: true })} />
                      <input type="hidden"  {...register("customer_id", { required: true })} />
                      <Mui.Grid container>
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.TextField label="Customer Name" size="small" variant="outlined" className="inputField"  {...register("name")} />
                                  {error.filter(error => error.path === 'name').length > 0 && <p className="error">{error.filter(error => error.path === 'name')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.TextField label="Customer Alias" size="small" variant="outlined" className="inputField"  {...register("alias")}  />
                                  {error.filter(error => error.path === 'alias').length > 0 && <p className="error">{error.filter(error => error.path === 'alias')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.TextField label="Contact / PIC" size="small" variant="outlined" className="inputField"  {...register("pic")} />
                                  {error.filter(error => error.path === 'pic').length > 0 && <p className="error">{error.filter(error => error.path === 'pic')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={7} className="innerForm">
                                  <Mui.TextField label="Customer ID / KTP" size="small" variant="outlined" className="inputField" {...register("customer_id_number")} />
                                  {error.filter(error => error.path === 'customer_id_number').length > 0 && <p className="error">{error.filter(error => error.path === 'customer_id_number')[0].msg}</p>}
                                  {error.filter(error => error.path === 'customer_id_card').length > 0 && <p className="error">{error.filter(error => error.path === 'customer_id_card')[0].msg}</p>}
                                  
                              </Mui.Grid>
                              
                              <Mui.Grid item xs={3} className="innerForm" textAlign={"right"}> 
                                  <MuiFileInput 
                                      placeholder = "UploadPhoto"
                                      value={fileKtp}  
                                      variant="outlined"
                                      size="small"
                                      InputProps={{
                                        startAdornment: <MuiIcon.AttachFile />
                                      }}
                                      clearIconButtonProps={{
                                        children: <MuiIcon.Close fontSize="12px" />
                                      }}
                                      onChange={(newfile) =>{
                                          setFileKtp(newfile)
                                        } 
                                      } 
                                  />
                              </Mui.Grid>
                              <Mui.Grid item xs={2} className="innerForm" textAlign={"right"}>
                                  <Mui.Button
                                          component="label"
                                          variant="contained"
                                          color={"warning" }
                                          tabIndex={-1}
                                          startIcon={  <MuiIcon.Photo />}
                                          onClick={()=>{
                                            setBoxModal(true)
                                            setUrlFile(urlKtp)
                                            console.log(urlKtp)
                                          }}
                                        >
                                          {"View"}
                                    </Mui.Button>
                                </Mui.Grid>
                                <Mui.Grid item xs={6} className="innerForm">
                                  <Mui.TextField label="Phone" type="number" size="small" variant="outlined" className="inputField" {...register("phone")} />
                                  {error.filter(error => error.path === 'phone').length > 0 && <p className="error">{error.filter(error => error.path === 'phone')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={6} className="innerForm">
                                  <Mui.TextField label="Email" size="small" variant="outlined" className="inputField"  {...register("email")} />
                                  {error.filter(error => error.path === 'email').length > 0 && <p className="error">{error.filter(error => error.path === 'email')[0].msg}</p>}
                              </Mui.Grid>
                              
                              <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.TextField label="Customer Address" size="small" variant="outlined" className="inputField"  {...register("address")} />
                                  {error.filter(error => error.path === 'address').length > 0 && <p className="error">{error.filter(error => error.path === 'address')[0].msg}</p>}
                              </Mui.Grid>
                          
                      </Mui.Grid>

                      <Mui.Divider style={{margin:'20px 10px'}} />

                      <Mui.Grid container>
                            <Mui.Grid item xs={4} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Cluster"
                                          defaultValue={state.customer?state.customer.cluster_id:''}
                                          {...register("cluster_id")}
                                      >
                                          {cluster.map((cluster,key) => (
                                          <Mui.MenuItem key={key} value={cluster.id}>{cluster.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'cluster_id').length > 0 && <p className="error">{error.filter(error => error.path === 'cluster_id')[0].msg}</p>}
                              </Mui.Grid>
                              
                              <Mui.Grid item xs={8} className="innerForm">
                                  <Mui.TextField label="Service Address" size="small" variant="outlined" className="inputField"  {...register("service_address")}  />
                                  {error.filter(error => error.path === 'service_address').length > 0 && <p className="error">{error.filter(error => error.path === 'service_address')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={7} className="innerForm">
                                  <Mui.TextField label="Service Coordinate" size="small" variant="outlined" className="inputField"  {...register("location_coordinate")}  />
                                  {error.filter(error => error.path === 'location_coordinate').length > 0 && <p className="error">{error.filter(error => error.path === 'location_coordinate')[0].msg}</p>}
                                  {error.filter(error => error.path === 'location_photo').length > 0 && <p className="error">{error.filter(error => error.path === 'location_photo')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm" textAlign={"right"}> 
                                  <MuiFileInput
                                      placeholder="UploadPhoto" 
                                      value={fileLoc} 
                                      variant="outlined"
                                      size="small"
                                      InputProps={{
                                        startAdornment: <MuiIcon.AttachFile />
                                      }}
                                      clearIconButtonProps={{
                                        children: <MuiIcon.Close fontSize="12px" />
                                      }}
                                      onChange={(newfile) =>{
                                          //console.log(newfile)
                                          setFileLoc(newfile)
                                        } 
                                      } 
                                  />
                              </Mui.Grid>
                              <Mui.Grid item xs={2} className="innerForm" textAlign={"right"}>
                                <Mui.Button
                                        component="label"
                                        variant="contained"
                                        color={"warning" }
                                        tabIndex={-1}
                                        startIcon={  <MuiIcon.Photo />}
                                        onClick={()=>{
                                          setBoxModal(true)
                                          setUrlFile(urlLoc)
                                        }}
                                      >
                                        {"View"}
                                  </Mui.Button>
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Region"
                                          defaultValue={selectedRegion}
                                          {...register("service_province")}
                                          onChange={(event) =>{
                                            //const myRegion = event.target.value.split("|");
                                            setSelectedRegion(event.target.value)
                                            setSelectedRegency(0)
                                            setSelectedDistrict(0)
                                            setSelectedVillage(0)
                                            setRegencies(Region[event.target.value].regencies)
                                            setDistricts(Region[event.target.value].regencies[0].districts)
                                            setVillages(Region[event.target.value].regencies[0].districts[0].villages)
                                          }}
                                      >
                                          {region.map((region,key) => (
                                          <Mui.MenuItem key={key} value={key}>{region.name}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'service_province').length > 0 && <p className="error">{error.filter(error => error.path === 'service_province')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          labelId="customer-regency-label"
                                          select
                                          size="small"
                                          id="customer-regency"
                                          label="Regency"
                                          defaultValue={selectedRegency}
                                          {...register("service_regency")}
                                          onChange={(event) =>{
                                            //const myRegion = selectedRegion.split("|");
                                            setSelectedRegency(event.target.value)
                                            setDistricts(Region[selectedRegion].regencies[event.target.value].districts)
                                            setVillages(Region[selectedRegion].regencies[event.target.value].districts[0].villages)
                                          }}
                                      >
                                      {regencies.map((regency,key) => (
                                          <Mui.MenuItem key={key} value={key}>{regency.name}</Mui.MenuItem>
                                      ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'service_regency').length > 0 && <p className="error">{error.filter(error => error.path === 'service_regency')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                    <Mui.TextField
                                                labelId="customer-district-label"
                                                select
                                                size="small"
                                                id="customer-district"
                                                label="District"
                                                defaultValue={selectedDistrict}
                                                {...register("service_district")}
                                                onChange={(event) =>{
                                                  //const myRegion = selectedRegion.split("|");
                                                  setDistricts(Region[selectedRegion].regencies[selectedRegency].districts)
                                                  setVillages(Region[selectedRegion].regencies[selectedRegency].districts[event.target.value].villages)
                                                }}
                                            >
                                            {districts.map((district,key) => (
                                                <Mui.MenuItem key={key} value={key}>{district.name}</Mui.MenuItem>
                                            ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'service_district').length > 0 && <p className="error">{error.filter(error => error.path === 'service_district')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                                labelId="customer-village-label"
                                                select
                                                size="small"
                                                id="customer-village"
                                                label="Village"
                                                defaultValue={selectedVillage}
                                            >
                                            {villages.map((village,key) => (
                                                <Mui.MenuItem key={key} value={key}>{village.name}</Mui.MenuItem>
                                            ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'so_date').length > 0 && <p className="error">{error.filter(error => error.path === 'so_date')[0].msg}</p>}
                              </Mui.Grid>
                              
                             
                             
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Product"
                                          defaultValue={state.customer?state.customer.product_id:''}
                                          {...register("product_id")}
                                      >
                                          {products.map((product,key) => (
                                          <Mui.MenuItem key={key} value={product.id}>{product.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'product_id').length > 0 && <p className="error">{error.filter(error => error.path === 'product_id')[0].msg}</p>}
                              </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Divider style={{margin:'20px 10px'}} />
                      <Mui.Grid container>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="ONT Brand"
                                          defaultValue={state.customer?(state.customer.ont_type):('')}
                                          {...register("ont_type")}
                                      >
                                          {ont.map((ont,key) => (
                                          <Mui.MenuItem key={key} value={ont.id}>{ont.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'ont_type').length > 0 && <p className="error">{error.filter(error => error.path === 'ont_type')[0].msg}</p>}
                                
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="Serial Number ONU" size="small" variant="outlined" className="inputField"  {...register("sn_onu")} />
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'sn_onu').length > 0 && <p className="error">{error.filter(error => error.path === 'sn_onu')[0].msg}</p>}
                                
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                    <MuiJoy.Button
                                      variant="solid" 
                                      loading={waitChangeOnt} 
                                      sx={{ margin: '0px 3px'}}
                                      startDecorator={<MuiIcon.Router />}
                                      loadingPosition="start"
                                      onClick={()=>{
                                        setAlertChONT('block')
                                        setAlertChONT1('none')
                                      }}
                                      > Replace ONT
                                    </MuiJoy.Button>
                                </Mui.FormControl>
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm" sx={{display:alertChONT}}>
                                  <Mui.Alert
                                      severity="error"
                                      action={
                                        <>
                                          <Mui.Button color="inherit" size="small" 
                                              onClick={()=>{
                                                setAlertChONT('none')
                                                setAlertChONT1('none')
                                              }}>
                                            No
                                          </Mui.Button>
                                          <Mui.Button color="inherit" size="small"
                                            onClick={()=>{
                                              changeONT(getValues("id"),getValues("ont_type"),getValues("sn_onu"))
                                            }}
                                          >
                                            Yes
                                          </Mui.Button>
                                        </>
                                      }
                                    >
                                      Are you sure to Replace ONT ?
                                    </Mui.Alert>
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm" sx={{display:alertChONT1}}>
                                    <Mui.Alert
                                      severity="success"
                                      action={
                                        <>
                                          <Mui.Button color="inherit" size="small" 
                                              onClick={()=>{
                                                setAlertChONT1('none')
                                              }}>
                                            Ok
                                          </Mui.Button>
                                          
                                        </>
                                      }
                                    >
                                      Replace ONT Successfully.
                                    </Mui.Alert>
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="PPPOE User" size="small" variant="outlined" className="inputField"  {...register("pppoe_user")} />
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'pppoe_user').length > 0 && <p className="error">{error.filter(error => error.path === 'pppoe_user')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="PPPOE Password" size="small" variant="outlined" className="inputField"  {...register("pppoe_pass")} />
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'pppoe_pass').length > 0 && <p className="error">{error.filter(error => error.path === 'pppoe_pass')[0].msg}</p>}
                                
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                    <MuiJoy.Button
                                      variant="solid" 
                                      loading={waitResetPPPOE} 
                                      sx={{ margin: '0px 3px'}}
                                      startDecorator={<MuiIcon.ChangeCircleOutlined />}
                                      loadingPosition="start"
                                      onClick={()=>{
                                        setAlertPPPOE('block')
                                        setAlertPPPOE1('none')
                                      }}
                                      > Reset PPPOE
                                    </MuiJoy.Button>
                                </Mui.FormControl>
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm" sx={{display:alertPPPOE}}>
                                  <Mui.Alert
                                      severity="error"
                                      action={
                                        <>
                                          <Mui.Button color="inherit" size="small" 
                                              onClick={()=>{
                                                setAlertPPPOE('none')
                                                setAlertPPPOE1('none')
                                              }}>
                                            No
                                          </Mui.Button>
                                          <Mui.Button color="inherit" size="small"
                                            onClick={()=>{
                                              resetPassPPPOE(state.id)
                                            }}
                                          >
                                            Yes
                                          </Mui.Button>
                                        </>
                                      }
                                    >
                                      Are you sure to reset PPPOE Password for this user ?
                                    </Mui.Alert>
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm" sx={{display:alertPPPOE1}}>
                                    <Mui.Alert
                                      severity="success"
                                      action={
                                        <>
                                          <Mui.Button color="inherit" size="small" 
                                              onClick={()=>{
                                                setAlertPPPOE1('none')
                                              }}>
                                            Ok
                                          </Mui.Button>
                                          
                                        </>
                                      }
                                    >
                                      Reset Password PPPOE Successfully.
                                    </Mui.Alert>
                              </Mui.Grid>
                      </Mui.Grid>
            </Mui.DialogContent>
        </form>
      </Mui.Drawer>   
    </>
  );
});

export default DrawerEditCustomer;
