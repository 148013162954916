import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';

import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';
import Region from '../Assets/region.json'

const DrawerCancelServiceOrder = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      submitForm: false,
      subscription_date: ''
      });
    var obj;

    useImperativeHandle(ref, () => ({
      openForm: (id,order,taxo) => {
        setState({ isForm: true, id: id, order: order,taxo: taxo})
        setValue('id',id)

        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

  const { register, formState: { errors }, handleSubmit, setValue,reset } = useForm()
  const onSubmit = async(data) => {
        loadingOn()
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization",cookies.token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams(new FormData(formRef.current));
       
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };

        await fetch(props.config.hostApi + '/service-order/cancel', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(obj.success){
              refreshParentOrder()
              loadingOff()
              reset()
            }else{
              setError(obj.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Cancel Service Order',
                msg: obj.message,
              })
            }
          })
          .then(()=>{
            console.log(obj)
          })
          
          
  }
 
  const loadingOn = () =>{
    console.log('Loading On')
    setLoading(true)
  }
  const loadingOff = () =>{
    console.log('LOading Off')
    setLoading(false)
  }
  const refreshParentOrder = () => {
    props.refreshParentOrderCancel()
    setState({isForm:false})
  }
  
  
  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters sx={{bgcolor: "#FBDEDF", color:'#DC2625'}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Cancel Service Order &raquo; ( {state.id} )
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.NotInterested />}
                    loadingPosition="start"
                    > Cancel SO
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                  <input type="hidden" {...register("id", { required: true })} />
                          <Mui.Grid container>
                           
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.Grid item md={12} 
                                      padding={3}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center">
                                      <Mui.Avatar sx={{ bgcolor: "#FBDEDF", color:'#DC2625', width: 80, height:80 }}>
                                          <MuiIcon.NotInterested sx={{fontSize:'40px'}} />
                                      </Mui.Avatar>
                                  </Mui.Grid>
                                  <Mui.Grid
                                    justifyContent="center"
                                    align="center"
                                    p={'0px 3px'}
                                      >
                                      <Mui.Typography variant='h6' p={'0px 2px'}>Are you sure you want to cancel this service order?</Mui.Typography>
                                  </Mui.Grid>

                            </Mui.Grid>
                          
                          <Mui.Divider style={{margin:'20px 10px'}} />
                            
                              <Mui.Grid item xs={12} className="innerForm">
                                <Mui.Grid
                                      justifyContent="center"
                                      align="center"
                                      p={'0px 3px'}
                                        >
                                      <Mui.Typography variant='subtitle1'>The reason of service order cancellation</Mui.Typography><br />
                                  </Mui.Grid>
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          multiline
                                          size="small"
                                          rows={6}
                                          label="Note"
                                          {...register("note")}
                                       />
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'note').length > 0 && <p className="error">{error.filter(error => error.path === 'note')[0].msg}</p>}
                              </Mui.Grid>
                              
                            
                      </Mui.Grid>
                  
            </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerCancelServiceOrder;
