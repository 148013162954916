import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from "../lib/MsgBox";
import { useForm, SubmitHandler } from "react-hook-form"

const DrawerEditOlt = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      console.log(data)
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));
      urlencoded.append("function", 11);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'devices/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              refreshParent()
              loadingOff()
              reset()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Save Olt',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            console.log(error)
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      olt:[],
      bng:[],
      vendor:[],
      vendor_product_type:[],
      snmp_version:[],
      remote_protocol:[]
    });

    useImperativeHandle(ref, () => ({
      openForm: (olt,bng,taxo) => {
        console.log(olt)
        setState({ 
          isForm: true, 
          olt:olt, 
          bng:bng,
          vendor: taxo.vendor,
          vendor_product_type:taxo.vendor_product_type,
          snmp_version: taxo.snmp_version,
          remote_protocol: taxo.remote_protocol
        })
        setValue("hostname", olt.hostname)
        setValue("id", olt.id)
        setValue("hostname", olt.hostname)
        setValue("ip_address", olt.ip_address)
        setValue("desc", olt.desc)
        setValue("snmp_community", olt.snmp_community)
        setValue("remote_data", olt.remote_data)
        setValue("user_access", olt.user_access)
        setValue("password_access", olt.password_access)
      }
   }));

   const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
    const refreshParent = (event) => {
      props.refreshOltUpdate()
      setState({
        isForm:false,
        olt:[],
        bng:[],
        vendor:[],
        vendor_product_type:[],
        snmp_version:[],
        remote_protocol:[]
      })
    }


  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} /*onClose={ ()=>{setState({isForm:false,id:'',device:[],taxo:[]})}}*/ PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Edit Olt with ID : {state.id}
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({
                  isForm:false,
                  id: '',
                  vendor:[],
                  vendor_product_type:[],
                  snmp_version:[],
                  remote_protocol:[]
                })}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
              <MuiJoy.Button
                type="submit"
                variant="solid" 
                loading={loading} 
                sx={{ margin: '0px 3px'}}
                startDecorator={<MuiIcon.Save />}
                loadingPosition="start"
                > Update
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
                <Mui.Grid container>
                    <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                        <span>* Require Field</span>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm" style={{display:'none'}}>
                          <Mui.TextField 
                            label="*ID" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                              {...register("id", { required: true, maxLength: 20})} 
                          />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="*Hostname" size="small" variant="outlined" className="inputField" 
                              InputLabelProps={{ shrink: true }}
                              {...register("hostname", 
                                        { 
                                          required: true, 
                                          maxLength: 20,
                                          shrink: true
                                        }
                                      )} 
                          />
                          {error.hostname && <p>{error.hostname.msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="*IP Address " size="small" variant="outlined" className="inputField" 
                              InputLabelProps={{ shrink: true }}
                              {...register("ip_address", { required: true })} />
                              {error.ip_address && <p className="error">{error.ip_address.msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Device Brand"
                                    defaultValue={state.olt?state.olt.vendor:0}
                                    {...register("vendor")} 
                                >
                                    {state.vendor.map((vendor,key) => (
                                    <Mui.MenuItem key={key} value={vendor.id}>{vendor.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'vendor').length > 0 && <p className="error">{error.filter(error => error.path === 'vendor')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Device Type"
                                    defaultValue={state.olt?state.olt.vendor_product_type:0}
                                    {...register("vendor_product_type")} 
                                >
                                    {state.vendor_product_type.map((type,key) => (
                                    <Mui.MenuItem key={key} value={type.id}>{type.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'vendor_product_type').length > 0 && <p className="error">{error.filter(error => error.path === 'vendor_product_type')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                        select
                                        size="small"
                                        label="*Number of PON"
                                        defaultValue={state.olt?state.olt.number_of_circuit_pon:0}
                                        {...register("number_of_circuit_pon")} 
                                    >
                                        
                                        <Mui.MenuItem key={0} value={4}>{4}</Mui.MenuItem>
                                        <Mui.MenuItem key={1} value={8}>{8}</Mui.MenuItem>
                                        <Mui.MenuItem key={2} value={16}>{16}</Mui.MenuItem>
                                        <Mui.MenuItem key={3} value={32}>{32}</Mui.MenuItem>
                                        <Mui.MenuItem key={3} value={64}>{64}</Mui.MenuItem>
                                        <Mui.MenuItem key={3} value={128}>{128}</Mui.MenuItem>
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'number_of_circuit_pon').length > 0 && <p className="error">{error.filter(error => error.path === 'number_of_circuit_pon')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*BNG"
                                    defaultValue={state.olt?state.olt.parent:0}
                                    {...register("parent")} 
                                >
                                    {state.bng.map((_bng,key) => (
                                    <Mui.MenuItem key={key} value={_bng.id}>{_bng.hostname}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'vendor').length > 0 && <p className="error">{error.filter(error => error.path === 'vendor')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.TextField 
                                  label="Description" 
                                  multiline
                                  size="small"
                                  rows={6}
                                  variant="outlined" 
                                  className="inputField" 
                                  {...register("desc")} />
                                  {error.filter(error => error.path === 'desc').length > 0 && <p className="error">{error.filter(error => error.path === 'desc')[0].msg}</p>}
                          </Mui.Grid>
                          <br />
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.Typography variant="h6" style={{marginLeft:'20px', marginTop:'20px'}}>Remote Access</Mui.Typography>
                              <Mui.Divider variant="middle"/>
                          
                          </Mui.Grid>
                          
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="Username " size="small" variant="outlined" className="inputField" {...register("user_access")} />
                              {error.user_access && <p className="error">{error.user_access.msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="Password" size="small" variant="outlined" className="inputField" {...register("password_access")} />
                              {error.password_access && <p className="error">{error.password_access.msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Remote Protocol"
                                    defaultValue={state.olt?state.olt.remote_protocol:''}
                                    {...register("remote_protocol")} 
                                >
                                    {state.remote_protocol.map((remoteProtocol,key) => (
                                    <Mui.MenuItem key={key} value={remoteProtocol.value}>{remoteProtocol.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'remote_protocol').length > 0 && <p className="error">{error.filter(error => error.path === 'remote_protocol')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="Remote Data (ex:port/url)" size="small" variant="outlined" className="inputField" {...register("remote_data")} />
                              {error.password_access && <p className="error">{error.password_access.msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                <Mui.InputLabel id="snmp-version" sx={{mt:-1}}>SNMP Version</Mui.InputLabel>
                                    <Mui.Select
                                    labelId="snmp-version"
                                    id="snmp-version-helper"
                                    label="Snmp Version"
                                    size="small"
                                    defaultValue={state.olt?state.olt.snmp_version:0}
                                    {...register("snmp_version")}
                                    >
                                        {state.snmp_version.map((snmpver,key) => (
                                        <Mui.MenuItem key={key} value={snmpver.id}>{snmpver.value}</Mui.MenuItem>
                                        ))}
                                    </Mui.Select>
                                </Mui.FormControl>
                                {error.snmp_version && <p>{error.snmp_version.message}</p>}
                          </Mui.Grid>

                          <Mui.Grid item xs={6} className="innerForm">
                              <Mui.TextField label="SNMP Community" size="small" variant="outlined" className="inputField" {...register("snmp_community")} />
                              {error.snmp_community && <p className="error">{error.snmp_community.msg}</p>}
                          </Mui.Grid>

                      
                </Mui.Grid>
        </Mui.DialogContent>
        
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerEditOlt;
