import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import * as MuiLab from '@mui/lab';
import { MuiFileInput } from 'mui-file-input';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';
import Region from '../Assets/region.json'

const DrawerAddCustomerService = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const childref = useRef();
    const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [rfs_date,setRfs_date] = React.useState()
    const [fileKtp, setFileKtp] = React.useState(null)
    const [fileLoc, setFileLoc] = React.useState(null)
    const [products,setProducts] = React.useState([]);
    const [cluster,setCluster] = React.useState([]);
    const [region,setRegion] = React.useState(Region);
    const [selectedRegion, setSelectedRegion] = React.useState(16);
    const [regencies,setRegencies] = React.useState(Region[16].regencies);
    const [selectedRegency, setSelectedRegency] = React.useState(0);
    const [districts,setDistricts] = React.useState(Region[16].regencies[0].districts);
    const [selectedDistrict,setSelectedDistrict]= React.useState(0)
    const [villages,setVillages] = React.useState(Region[16].regencies[0].districts[0].villages)
    const [selectedVillage,setSelectedVillage] = React.useState(0)
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      submitForm: false,
      subscription_date: ''
      });
    var obj;

    useImperativeHandle(ref, () => ({
      openForm: (id,customer,taxo) => {
        setState({ isForm: true, id: id, customer: customer,taxo: taxo})
        setProducts(taxo.product)
        setCluster(taxo.cluster)
        console.log(taxo)
        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

  const { register, formState: { errors }, handleSubmit, setValue } = useForm()
  const onSubmit = (data) => {
        loadingOn()
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization",cookies.token);

        const formdata = new FormData(formRef.current);
        formdata.append("customer_id", state.customer.id)
        if(fileLoc){formdata.append("location_photo", fileLoc, fileLoc.name)}
        if(fileKtp){formdata.append("customer_id_card", fileKtp, fileKtp.name)}

/*
        let formObject = Object.fromEntries(formdata.entries());
        console.log('=====FORM REF Data : ' + cookies.token)
        console.log(formObject);*/
       
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };

       fetch(props.config.hostApi + 'service-order/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(obj.success){
              refreshParentCustomers()
              loadingOff()
            }else{
              setError(obj.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Add New Service Customer',
                msg: obj.message,
              })
            }
          })
          .then(()=>{
            console.log(obj)
          })
          
          
  }
 
  const loadingOn = () =>{
    console.log('Loading On')
    setLoading(true)
  }
  const loadingOff = () =>{
    console.log('LOading Off')
    setLoading(false)
  }
  const refreshParentCustomers = () => {
    props.refreshParentCustomers()
    setState({isForm:false})
  }
  
  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{backgroundColor:"#eeeeee"}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Add Service for Customer ID : {state.id}
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.Save />}
                    loadingPosition="start"
                    > Save
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                          <Mui.Grid container>
                            <Mui.Grid item xs={4} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Cluster"
                                          {...register("cluster_id")}
                                      >
                                          {cluster.map((cluster,key) => (
                                          <Mui.MenuItem key={key} value={cluster.id}>{cluster.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'cluster_id').length > 0 && <p className="error">{error.filter(error => error.path === 'cluster_id')[0].msg}</p>}
                              </Mui.Grid>
                              
                              <Mui.Grid item xs={8} className="innerForm">
                                  <Mui.TextField label="Service Address" size="small" variant="outlined" className="inputField"  {...register("service_address")}  />
                                  {error.filter(error => error.path === 'service_address').length > 0 && <p className="error">{error.filter(error => error.path === 'service_address')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={9} className="innerForm">
                                  <Mui.TextField label="Service Coordinate" size="small" variant="outlined" className="inputField"  {...register("location_coordinate")}  />
                                  {error.filter(error => error.path === 'location_coordinate').length > 0 && <p className="error">{error.filter(error => error.path === 'location_coordinate')[0].msg}</p>}
                                  {error.filter(error => error.path === 'location_photo').length > 0 && <p className="error">{error.filter(error => error.path === 'location_photo')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm" textAlign={"right"}> 
                                  <MuiFileInput
                                      placeholder="UploadPhoto" 
                                      value={fileLoc} 
                                      variant="outlined"
                                      size="small"
                                      InputProps={{
                                        startAdornment: <MuiIcon.AttachFile />
                                      }}
                                      clearIconButtonProps={{
                                        children: <MuiIcon.Close fontSize="12px" />
                                      }}
                                      onChange={(newfile) =>{
                                          //console.log(newfile)
                                          setFileLoc(newfile)
                                        } 
                                      } 
                                  />
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Region"
                                          defaultValue={selectedRegion}
                                          {...register("service_province")}
                                          onChange={(event) =>{
                                            //const myRegion = event.target.value.split("|");
                                            setSelectedRegion(event.target.value)
                                            setSelectedRegency(0)
                                            setSelectedDistrict(0)
                                            setSelectedVillage(0)
                                            setRegencies(Region[event.target.value].regencies)
                                            setDistricts(Region[event.target.value].regencies[0].districts)
                                            setVillages(Region[event.target.value].regencies[0].districts[0].villages)
                                          }}
                                      >
                                          {region.map((region,key) => (
                                          <Mui.MenuItem key={key} value={key}>{region.name}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'service_province').length > 0 && <p className="error">{error.filter(error => error.path === 'service_province')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          labelId="customer-regency-label"
                                          select
                                          size="small"
                                          id="customer-regency"
                                          label="Regency"
                                          defaultValue={selectedRegency}
                                          {...register("service_regency")}
                                          onChange={(event) =>{
                                            //const myRegion = selectedRegion.split("|");
                                            setSelectedRegency(event.target.value)
                                            setDistricts(Region[selectedRegion].regencies[event.target.value].districts)
                                            setVillages(Region[selectedRegion].regencies[event.target.value].districts[0].villages)
                                          }}
                                      >
                                      {regencies.map((regency,key) => (
                                          <Mui.MenuItem key={key} value={key}>{regency.name}</Mui.MenuItem>
                                      ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'service_regency').length > 0 && <p className="error">{error.filter(error => error.path === 'service_regency')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                    <Mui.TextField
                                                labelId="customer-district-label"
                                                select
                                                size="small"
                                                id="customer-district"
                                                label="District"
                                                defaultValue={selectedDistrict}
                                                {...register("service_district")}
                                                onChange={(event) =>{
                                                  //const myRegion = selectedRegion.split("|");
                                                  setDistricts(Region[selectedRegion].regencies[selectedRegency].districts)
                                                  setVillages(Region[selectedRegion].regencies[selectedRegency].districts[event.target.value].villages)
                                                }}
                                            >
                                            {districts.map((district,key) => (
                                                <Mui.MenuItem key={key} value={key}>{district.name}</Mui.MenuItem>
                                            ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'service_district').length > 0 && <p className="error">{error.filter(error => error.path === 'service_district')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={3} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                                labelId="customer-village-label"
                                                select
                                                size="small"
                                                id="customer-village"
                                                label="Village"
                                                defaultValue={selectedVillage}
                                            >
                                            {villages.map((village,key) => (
                                                <Mui.MenuItem key={key} value={key}>{village.name}</Mui.MenuItem>
                                            ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'so_date').length > 0 && <p className="error">{error.filter(error => error.path === 'so_date')[0].msg}</p>}
                              </Mui.Grid>
                              
                             
                             
                              
                            <Mui.Grid item xs={4} className="innerForm">
                                <input type="hidden" value={rfs_date} {...register("rfs_date")} />
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker label="RFS Date" 
                                              slotProps={{ textField: { size: 'small' } }}  
                                              format="YYYY-MM-DD" 
                                              onChange={(event)=>{
                                                  setRfs_date(new Date(event).toLocaleDateString("sv-SE"))
                                                  setValue("rfs_date", new Date(event).toLocaleDateString("sv-SE"))
                                              }} />
                                </LocalizationProvider>
                                {error.filter(error => error.path === 'rfs_date').length > 0 && <p className="error">{error.filter(error => error.path === 'rfs_date')[0].msg}</p>}
                            </Mui.Grid>
                            <Mui.Grid item xs={8} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Product"
                                          {...register("product_id")}
                                      >
                                          {products.map((product,key) => (
                                          <Mui.MenuItem key={key} value={product.id}>{product.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'product_id').length > 0 && <p className="error">{error.filter(error => error.path === 'product_id')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          multiline
                                          size="small"
                                          rows={6}
                                          label="Note"
                                          {...register("note")}
                                       />
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'product_id').length > 0 && <p className="error">{error.filter(error => error.path === 'product_id')[0].msg}</p>}
                              </Mui.Grid>
                              
                            
                      </Mui.Grid>
                  
            </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddCustomerService;
