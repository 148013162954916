import React,{ useRef,useState,createContext } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"

import Header from '../lib/Header';
import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import GetStatus from '../lib/GetStatus';
import DrawerAddCustomer from '../drawer/DrawerAddCustomer';
import Cstr from '../lib/ConvertString';
import DrawerEditProduct from '../drawer/DrawerEditProduct';
import DrawerDeleteProduct from '../drawer/DrawerDeleteProduct';
import DrawerAddProduct from '../drawer/DrawerAddProduct';



const Products = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [radius,setRadius] = React.useState([])
  const [billTemplate,setBillTemplate] = React.useState([])
  const [loading,setLoading] = React.useState(false)

  const addRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();
  const formRef = useRef();

  const [taxo,setTaxo] = useState([]);

  const [page,setPage] = React.useState(1)
  const changePage = (event,value) => {
    setPage(value);
    getTaxoList()
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    device_id :'',
    perPage: 10

  })
  const { register, setValue, handleSubmit,reset } = useForm()
  var obj;
  const onSubmit =  (data) => { 
      setPage(1)
      setFilter({
        search: data.search,
        device_id: data.device_id,
        perPage: data.perPage
        
      })
     getTaxoList()
      
    
  }


  const afterSave=()=>{
    getTaxoList()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Product',
      msg: 'Saving new product successfully.'
    })
  }

  const afterDelete=()=>{
    getTaxoList()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete Product',
      msg: 'Delete Product successfully'
    })
  }
  const afterUpdate=()=>{
    getTaxoList()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Product',
      msg: 'Update Product successfully'
    })
  }
  const getBillTemplate = () => {
    setContentLoading(true)
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'invoice/template',
     {
      perPage:100,
      page:1,
     },
     { headers: header })
      .then(res => {
          setBillTemplate(res.data.data)
          setContentLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setContentLoading(false)
      })
  }
  const getRadius = () => {
    setContentLoading(true)
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'devices',
     {
      perPage:100,
      page:1,
      function:[7] // <= Filter Device by Radius
     },
     { headers: header })
      .then(res => {
          setRadius(res.data.data)
          setContentLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setContentLoading(false)
      })
  }
  const getTaxoList = () => {
    setContentLoading(true)
    const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 'taxo/list',{
        object: ['product'],
        search: filter.search,
        device_id: filter.device_id,
        perPage: filter.perPage,
        page: page
      },
       { headers: header })
        .then(res => {
          console.log(res.data.data)
            setTaxo(res.data.data)
            setPagination(res.data.pagination)
            setContentLoading(false)
          })
        .catch((error) => {
          console.log(error)
          setContentLoading(false)
        })
  }

  React.useEffect(() => {
    getRadius();
    getBillTemplate();
    getTaxoList();
  }, [filter,page]); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAddProduct ref={addRef} config={props.config} refreshProductSave={afterSave} />
      <DrawerEditProduct ref={editRef} config={props.config} refreshProductUpdate={afterUpdate} />
      <DrawerDeleteProduct ref={deleteRef} config={props.config} refreshProductDelete={afterDelete} />
      
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Products ({pagination.totalRecords}) 
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <Mui.Chip 
              label="Add New Product" 
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.BookmarkAdd />}
              onClick={ ()=> {
                addRef.current.openForm(radius,billTemplate);
              }}
              />
            <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                //setVariant('soft');
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />
      </Mui.Toolbar>
      
      
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>

              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'160px',margin: '5px'}}>
              <Mui.FormControl size="small" className="inputField">
                      <input type='hidden' {...register("device_id")} />
                      <Mui.TextField
                          select
                          size="small"
                          label="Radius"
                          onChange={(event)=>(
                            event.target.value != 0 ? setValue('device_id',[event.target.value]) : setValue('device_id',[])
                            
                          )}
                      >
                        <Mui.MenuItem key={-1} value={0}>All Radius</Mui.MenuItem>
                        {radius.map((_radius,key) => (
                          <Mui.MenuItem key={key} value={_radius.id}>{_radius.hostname}</Mui.MenuItem>
                          ))}
                          
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
              <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Rows</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Rows</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Rows</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Rows</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={loading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
            ) : (
              <>
                
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>ID</Mui.TableCell>
                                <Mui.TableCell>Sort</Mui.TableCell>
                                <Mui.TableCell>Radius</Mui.TableCell>
                                <Mui.TableCell>Product Name</Mui.TableCell>
                                <Mui.TableCell align='right'>Price</Mui.TableCell>
                                <Mui.TableCell align='right'>Speed (in mbps )</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { taxo.map((product,key) => (
                          
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <Mui.TableCell component="th" scope="row">
                                    {product.id}
                                </Mui.TableCell>
                                <Mui.TableCell>{product.sort}</Mui.TableCell>
                                <Mui.TableCell>{product.device_hostname}</Mui.TableCell>
                                <Mui.TableCell>{product.value}</Mui.TableCell>
                                <Mui.TableCell align='right'>{Cstr('cur',product.product_price)}</Mui.TableCell>
                                <Mui.TableCell align="right">{Cstr('num',product.product_limit)}</Mui.TableCell>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                    <MuiJoy.MenuButton
                                      slots={{ root: MuiJoy.IconButton }}
                                      slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                    >
                                      <MuiIcon.MoreVert />
                                    </MuiJoy.MenuButton>
                                    <MuiJoy.Menu placement="bottom-end">

                                    
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                            editRef.current.openForm(key,taxo[key],radius,billTemplate);
                                        }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.Edit />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit
                                      </MuiJoy.MenuItem>
                                      
                                      <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                            deleteRef.current.openForm(product.id); 
                                          }}>
                                        <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                          <MuiIcon.NotInterested />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Delete
                                      </MuiJoy.MenuItem>
                                      
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                                      ))}
                        </Mui.TableBody>
                    </Mui.Table>
                </Mui.Grid>
                <Mui.Grid item xs={12} >
                    <Mui.Pagination 
                        sx={{ 
                          '& > .MuiPagination-ul': {
                            justifyContent: 'center',
                          },
                          p:2,
                        }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
                </Mui.Grid>
              </>
            )}
    </Mui.Grid>

    </div>
  );
}

export default Products;
