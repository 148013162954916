import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';

import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';


const DrawerEditTaxonomy = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, formState: { errors }, reset, handleSubmit, setValue } = useForm()
    const onSubmit = (data) => {
      console.log('disiniii')
      loadingOn()
          
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
      const urlencoded = new URLSearchParams(new FormData(formRef.current));
  
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };
  
  
      fetch(props.config.hostApi + 'taxo/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            if(result.success){
              refreshParent()
              loadingOff()
              reset()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Save Taxonomy',
                msg: result.message,
              })
            }
          })
          .then(()=>{
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      id: '',
      taxog:'',
      taxo:[],
      label:''
    });

    useImperativeHandle(ref, () => ({
      openForm: (id,taxog, taxo) => {
          setState({ isForm: true, id: id, taxog:taxog, taxo: taxo, label : taxog[0].toUpperCase() + taxog.slice(1)})
          setValue("sort", taxo.sort)
          setValue("value", taxo.value)
          setValue("desc", taxo.desc)
      }
   }));

   const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const refreshParent = () => {
    props.refreshParentUpdate()
    setState({isForm:false})
  }

  React.useEffect(() => {
    

  },[]); 

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                    <Mui.Typography
                      sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                      color="text.secondary"
                    >
                      Taxonomy &raquo; {state.id}
                    </Mui.Typography>
                    <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                    
                    <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isForm:false,id:'',device:[],taxo:[]})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.Save />}
                    loadingPosition="start"
                    > Update
                  </MuiJoy.Button>
              </Mui.Toolbar>
              <Mui.DialogContent>
                      <input type="hidden" value={state.id} {...register("id", { required: true })} />
                      <input type="hidden" value={state.taxog} {...register("object", { required: true })} />
                      <Mui.Grid container>
                          <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                              <span>* Require Field</span>
                          </Mui.Grid>
                            <Mui.Grid item xs={12} className="innerForm">
                                <Mui.TextField label="Sort" size="small" variant="outlined" className="inputField" 
                                    type="number"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("sort")} 
                                />
                                
                                {error.filter(error => error.path === 'sort').length > 0 && <p className="error">{error.filter(error => error.path === 'sort')[0].msg}</p>}
                            </Mui.Grid>
                            <Mui.Grid item xs={12} className="innerForm">
                                <Mui.TextField label={"*"+ state.label +' Name'} size="small" variant="outlined" className="inputField" 
                                    InputLabelProps={{ shrink: true }}
                                    {...register("value")} />
                                    
                                {error.filter(error => error.path === 'value').length > 0 && <p className="error">{error.filter(error => error.path === 'value')[0].msg}</p>}
                            </Mui.Grid>
                            <Mui.Grid item xs={12} className="innerForm">
                              <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField
                                      multiline
                                      size="small"
                                      rows={6}
                                      label="Description"
                                      {...register("desc")}
                                    />
                              </Mui.FormControl>
                              {error.filter(error => error.path === 'desc').length > 0 && <p className="error">{error.filter(error => error.path === 'desc')[0].msg}</p>}
                          </Mui.Grid>
                      </Mui.Grid>
                  
              </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerEditTaxonomy;
