import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from '../lib/MsgBox';
import { useForm, SubmitHandler } from "react-hook-form";

const DrawerRefundInvoice = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      invoices: []
      });

    useImperativeHandle(ref, () => ({
      openForm: (checked) => {
        setState({ isForm: true, invoices: checked})
        console.log(checked)

        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));
  

  
  const { register, setValue, handleSubmit,reset } = useForm()
  const onSubmit =(data)=>{
    console.log(state.invoices.filter(_invoices => _invoices.status === true))
    if(state.invoices.filter(_invoices => _invoices.status === true).length>0){
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Authorization",cookies.token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const checkedObject = state.invoices.filter(_invoices => _invoices.status === true)
        const urlencoded = new URLSearchParams(new FormData(formRef.current));
        for(var i=0; i < checkedObject.length; i++){
          console.log(checkedObject[i].id)
          urlencoded.append("id[]",checkedObject[i].id);
        }

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };
          fetch(props.config.hostApi + 'invoice/refund/multiple', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              console.log(result.data)
              setLoading(false)
              refreshParent()
            }else{
              console.log(result.errors)
              setError(result.errors)
              setLoading(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Set Refund Invoices',
                msg: result.message
              })
            }
          })
          .then((error)=>{
            console.log(error)
            setLoading(false)
          })
          
        }
  }
 
  const refreshParent = () => {
    props.refreshRefund()
    setState({isForm:false,invoices:[]})
  }

  return (
    <>
      <MsgBox config={isMsgBox} />
        <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <Mui.Toolbar disableGutters sx={{bgcolor: "#FBDEDF", color:'#DC2625'}} className="formHeader"> 
                    <Mui.Typography
                      sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                      color="text.secondary"
                    >
                      Refund Invoice
                    </Mui.Typography>
                    <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                    
                    <MuiJoy.Button
                      variant="solid"
                      onClick={()=> {
                        if(!loading){ setState({isForm:false,invoices:[]})}
                      }}  
                      startDecorator={<MuiIcon.Replay/>}
                    > Back 
                    </MuiJoy.Button>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      color="danger"
                      loading={loading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.CreditCardOff />}
                      loadingPosition="start"
                      > Refund
                    </MuiJoy.Button>
                    
              </Mui.Toolbar>
              <Mui.DialogContent sx={formContentStyle} className="formContent">
                            <Mui.Grid container>
                              <Mui.Grid item xs={12} className="innerForm">
                                    <Mui.Grid item md={12} 
                                        padding={3}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <Mui.Avatar sx={{ bgcolor: "#FBDEDF", color:'#DC2625', width: 80, height:80 }}>
                                            <MuiIcon.CreditCardOff sx={{fontSize:'40px'}} />
                                        </Mui.Avatar>
                                    </Mui.Grid>
                                    <Mui.Grid
                                      justifyContent="center"
                                      align="center"
                                      p={'0px 3px'}
                                        >
                                        <Mui.Typography variant='h6' p={'0px 2px'}>Are you sure you want to Refund {state.invoices.filter(_invoices => _invoices.status === true).length} Invoice{state.invoices.filter(_invoices => _invoices.status === true).length>1 ? ('s') : ('')}  ?</Mui.Typography>
                                    </Mui.Grid>
                                    <Mui.Divider style={{margin:'20px 10px'}} />
                              
                              <Mui.Grid item xs={12} className="innerForm">
                                <Mui.Grid
                                      justifyContent="center"
                                      align="center"
                                      p={'0px 3px'}
                                        >
                                      <Mui.Typography variant='subtitle1'>The reason of invoice refund : </Mui.Typography><br />
                                  </Mui.Grid>
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          multiline
                                          size="small"
                                          rows={6}
                                          label="Note"
                                          {...register("note")}
                                      />
                                  </Mui.FormControl>
                                  {/*error.filter(error => error.path === 'note').length > 0 && <p className="error">{error.filter(error => error.path === 'note')[0].msg}</p>*/}
                              </Mui.Grid>
                              </Mui.Grid>      
                        </Mui.Grid>
                    
              </Mui.DialogContent>      
          </form>
        </Mui.Drawer>  
    </>
  );
});

export default DrawerRefundInvoice;
