import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';
import * as MuiJoy from '@mui/joy';

import '../lib/style.css'
import Header from '../lib/Header';

import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import DrawerAddTaxonomy from '../drawer/DrawerAddTaxonomy';
import DrawerEditTaxonomy from '../drawer/DrawerEditTaxonomy';
import DrawerDeleteTaxonomy from '../drawer/DrawerDeleteTaxonomy';


const Taxonomy = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [value, setValue] = React.useState('1');
  const addRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();
  const [state, setState] = useState({
    isAddForm: true,
    isEditForm: false,
    loading: false,
    tabKey: ''
  })
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [taxo,setTaxo] = useState([]);
  const [taxoGroup,setTaxoGroup] = useState([]);

  const afterSave=()=>{
    getTaxo()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Taxonomy',
      msg: 'Saving new Taxonomy successfully'
    })
  }
  const afterUpdate=()=>{
    getTaxo()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Taxonomy',
      msg: 'Update Taxonomy successfully'
    })
  }
  const deleteConfirm = (id,value) => {
      setisMsgBox({
        open: true,
        status: 'warning',
        title:'Delete Taxonomy',
        msg: 'Are you sure delete Taxonomy : '+value+'?',
        button: 1,
        action:'delete',
        idTarget:id
      })
  }
  const afterDelete=()=>{
    getTaxo()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete Taxonomy',
      msg: 'Delete Taxonomy successfully'
    })
  }
  const getTaxo = () => {
    const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 'taxo',
       {},
       { headers: header })
        .then(res => {
            console.log(res.data.data['cluster'])
            setTaxoGroup(Object.keys(res.data.data))
            setState({tabKey: Object.keys(res.data.data)[0]})
            setTaxo(res.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const handleRowEditStop=(event)=>{
    console.log(event)
  }
  const processRowUpdate=()=>{
    console.log('processRowUpdate')

  }

  const handleChange = (event, newValue) => {
    setState({tabKey:newValue});
  };

  React.useEffect(() => {
    getTaxo();
  }, []); 

  return (
    <div>
    <MsgBox config={isMsgBox} />
    <DrawerAddTaxonomy ref={addRef} config={props.config} refreshParent={afterSave} />
    <DrawerEditTaxonomy ref={editRef} config={props.config} refreshParentUpdate={afterUpdate} />
    <DrawerDeleteTaxonomy ref={deleteRef} config={props.config} refreshParentDelete={afterDelete} />
    <Header config={props.config} />
    <Mui.Grid className='topBar' container style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
      <Mui.Grid item md={12}>
        <Mui.Toolbar disableGutters>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Taxonomy
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <Mui.Chip 
              label="Add New Taxonomy" 
              variant="outlined" 
              onClick={ 
                ()=> {
                  addRef.current.openForm();
                }}
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.AddLink />}
            />
        </Mui.Toolbar>
      </Mui.Grid>
    </Mui.Grid>

    <Mui.Grid container spacing={2} style={{padding:'20px'}}>
      <Mui.Grid item lg={12} sx={{width:'100%'}}>
          <Mui.Box sx={{ width: '100%', typography: 'body1' }}>
            <MuiLab.TabContext value={state.tabKey}>
              <Mui.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <MuiLab.TabList onChange={handleChange} aria-label="lab API tabs example">
                  {taxoGroup.map((taxoG,key) => (
                    <Mui.Tab label={'> '+taxoG} value={taxoG} />
                  ))}
                </MuiLab.TabList>
              </Mui.Box>
                {taxoGroup.map((taxoG,key) => (
                    <MuiLab.TabPanel value={taxoG} >
                         <Mui.Grid container spacing={2} width='100%' >
                            <Mui.Grid item lg={12}>
                                <Mui.Table sx={{ minWidth: 40 }} aria-label="simple table">
                                    <Mui.TableHead>
                                        <Mui.TableRow>
                                            <Mui.TableCell style={{width:100}}>ID</Mui.TableCell>
                                            <Mui.TableCell style={{width:100}}>Sort</Mui.TableCell>
                                            <Mui.TableCell>{taxoG[0].toUpperCase() + taxoG.slice(1)+' Name'}</Mui.TableCell>
                                            <Mui.TableCell>Description</Mui.TableCell>
                                            <Mui.TableCell>&nbsp;</Mui.TableCell>
                                        </Mui.TableRow>
                                    </Mui.TableHead>
                                    <Mui.TableBody>
                                        {taxo[taxoG].map((taxo,key) => (
                                            <Mui.TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                              <Mui.TableCell component="th" scope="row">{taxo.id}</Mui.TableCell>
                                              <Mui.TableCell>{taxo.sort}</Mui.TableCell>
                                              <Mui.TableCell>{taxo.value}</Mui.TableCell>
                                              <Mui.TableCell>{taxo.desc}</Mui.TableCell>
                                              <Mui.TableCell align="right">
                                                <MuiJoy.Dropdown>
                                                  <MuiJoy.MenuButton
                                                    slots={{ root: MuiJoy.IconButton }}
                                                    slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                                  >
                                                    <MuiIcon.MoreVert />
                                                  </MuiJoy.MenuButton>
                                                  <MuiJoy.Menu placement="bottom-end">
                                                    <MuiJoy.MenuItem onClick={ ()=> {
                                                        editRef.current.openForm(taxo.id,taxoG,taxo); 
                                                      }}>
                                                      <MuiJoy.ListItemDecorator>
                                                        <MuiIcon.Edit />
                                                      </MuiJoy.ListItemDecorator>{' '}
                                                      Edit
                                                    </MuiJoy.MenuItem>
                                                    
                                                    <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                                      deleteRef.current.openForm(taxo.id,taxoG,taxo); 
                                                    }}>
                                                      <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                                        <MuiIcon.DeleteForever />
                                                      </MuiJoy.ListItemDecorator>{' '}
                                                      Delete
                                                    </MuiJoy.MenuItem>
                                                  </MuiJoy.Menu>
                                                </MuiJoy.Dropdown>
                                              </Mui.TableCell>
                                            </Mui.TableRow>
                                            
                                                ))}
                                    
                                    </Mui.TableBody>
                                        
                                </Mui.Table>
                            </Mui.Grid>

                          </Mui.Grid>
                        
                    </MuiLab.TabPanel>
                  ))}
            </MuiLab.TabContext>
          </Mui.Box>
          </Mui.Grid>

</Mui.Grid>


</div>
);
}

export default Taxonomy;