import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from "../lib/MsgBox";
import Region from '../Assets/region.json'

const DrawerInfoServiceOrder = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const [products,setProducts] = React.useState([]);
    const [ont,setOnt] = React.useState([]);
    const [selectedProduct,setSelectedProduct] = React.useState();
    const [fileKtp, setFileKtp] = React.useState(null)
    const [urlFile,setUrlFile] = React.useState()
    const [urlLoc,setUrlLoc] = React.useState()
    const [urlKtp,setUrlKtp] = React.useState()
    const [fileLoc, setFileLoc] = React.useState(null)
    const [boxModal,setBoxModal] = React.useState(false)
    const [cluster,setCluster] = React.useState([]);
    const [region,setRegion] = React.useState(Region);
    const [selectedRegion, setSelectedRegion] = React.useState(16);
    const [regencies,setRegencies] = React.useState(Region[16].regencies);
    const [selectedRegency, setSelectedRegency] = React.useState(0);
    const [districts,setDistricts] = React.useState(Region[16].regencies[0].districts);
    const [selectedDistrict,setSelectedDistrict]= React.useState(0)
    const [villages,setVillages] = React.useState(Region[16].regencies[0].districts[0].villages)
    const [selectedVillage,setSelectedVillage] = React.useState(0)
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})

    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm()
    const [state, setState] = React.useState({
      isForm: false, id: 0, order: [],taxo: []
      });
    const [isMsgBox, setisMsgBox] = React.useState({
        open: false,
        status: 'ok',
        title:'',
        msg: '',
        action:''
      })
  
      const boxModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };

    useImperativeHandle(ref, () => ({
      openForm: (id,order,taxo) => {
        setState({ isForm: true, id: id, order: order,taxo: taxo})
        console.log(order)
        console.log(taxo.ont)
        console.log(order.customer_service.ont_type)
        console.log(taxo.ont.filter(ont => ont.id === order.customer_service.ont_type))

        setUrlLoc(order.customer_service.location_photo)
        setValue('customer_service_id', order.customer_service_id)
        setValue('id', order.id)
        setValue('so_date', order.so_date)
        setValue('rfs_date', order.rfs_date)
        setValue('service_action_name',order.service_action_name)
        setValue('requested_by_name',order.requested_by_name)
        setValue('customer_id',order.customer_service.customer.id)
        setValue('customer_name',order.customer_name)
        setValue('alias',order.customer_service.customer.alias)
        setValue('pic',order.customer_service.customer.pic)
        setValue('phone',order.customer_service.customer.phone)
        setValue('email',order.customer_service.customer.email)
        setValue('cluster',taxo.cluster.filter(cluster => cluster.id === order.customer_service.cluster_id)[0].value)
        setValue('service_address',order.customer_service.service_address)
        setValue('location_coordinate',order.customer_service.location_coordinate)
        setValue('region',region[order.customer_service.service_province].name)
        setValue('regency',region[order.customer_service.service_province].regencies[order.customer_service.service_regency].name)
        setValue('district',region[order.customer_service.service_province].regencies[order.customer_service.service_regency].districts[order.customer_service.service_district].name)
        setValue('product',order.product_name)
        //setValue('ont_type',taxo.ont.length>0 ? taxo.ont.filter(ont => ont.id === order.customer_service.ont_type)[0].value:'')
        setValue('sn_onu',order.customer_service.sn_onu)
        setValue('pppoe_user',order.customer_service.pppoe_user)
        setValue('pppoe_pass',order.customer_service.pppoe_pass)


        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

   

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
      <Mui.Modal
        open={boxModal}
        onClose={()=>{setBoxModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Mui.Box sx={boxModalStyle}>
          <img src={urlFile} />
        </Mui.Box>
      </Mui.Modal>  
            <Mui.Toolbar disableGutters style={{backgroundColor:"#eeeeee"}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Info Service Order &raquo; ( {state.id} )
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      setState({isForm: false, id: 0, order: [],taxo: []})}
                    }  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                 
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                  <Mui.Grid container sx={{backgroundColor:'#E5F6FD', padding:'40px'}}>
                      <MuiJoy.Stepper orientation="vertical">
                        <MuiJoy.Step
                          indicator={
                            <MuiJoy.StepIndicator variant="solid" color="warning">1</MuiJoy.StepIndicator>
                          }
                        >
                          <Mui.Typography>Requested by </Mui.Typography>

                          <Mui.Stack spacing={1}>
                            <Mui.Typography level="body-sm">
                              { state.order.requested_by_name } <br />
                              { state.order.so_date } 
                            </Mui.Typography>
                          </Mui.Stack>
                        </MuiJoy.Step>
                        {state.order.status===1 ? ( 
                            <MuiJoy.Step indicator={
                              <MuiJoy.StepIndicator variant="solid" color="success"  >2</MuiJoy.StepIndicator>}>
                              <Mui.Typography>{state.order.service_action_name} &raquo; {state.order.status_name} by</Mui.Typography>
                              <Mui.Stack spacing={1}>
                                <Mui.Typography level="body-sm">
                                  { state.order.activation_pic_name } <br />
                                  { state.order.activation_date } 
                                </Mui.Typography>
                              </Mui.Stack>
                            </MuiJoy.Step>
                        ) : ('') }
                        {state.order.status === 4 ? ( 
                            <MuiJoy.Step indicator={
                              <MuiJoy.StepIndicator variant="solid" color="danger"  >2</MuiJoy.StepIndicator>}>
                              <Mui.Typography>{state.order.service_action_name} &raquo; {state.order.status_name} by</Mui.Typography>
                              <Mui.Stack spacing={1}>
                                <Mui.Typography level="body-sm">
                                  { state.order.activation_pic_name } <br />
                                  { state.order.activation_date } <br /><br />
                                  <Mui.Typography sx={{fontStyle:'italic'}}><strong>Note</strong> : { state.order.note } </Mui.Typography>
                                </Mui.Typography>
                              </Mui.Stack>
                            </MuiJoy.Step>
                        ) : ('') }
                       
                      </MuiJoy.Stepper>
                  </Mui.Grid>
                  <Mui.Grid container style={{marginTop:'50px'}}>
                      <Mui.Grid item xs={4} className="innerForm">
                          <Mui.TextField label="Service ID" size="small" variant="outlined" className="inputField"  {...register("customer_service_id")} />
                      </Mui.Grid>
                      <Mui.Grid item xs={4} className="innerForm">
                          <Mui.TextField label="SO ID" size="small" variant="outlined" className="inputField"  {...register("id")} />
                      </Mui.Grid>
                      <Mui.Grid item xs={4} className="innerForm">
                          <Mui.TextField label="SO Date" size="small" variant="outlined" className="inputField"  {...register("so_date")}  />
                      </Mui.Grid>
                      <Mui.Grid item xs={7} className="innerForm">
                          <Mui.TextField label="RFS Date" size="small" variant="outlined" className="inputField"  {...register("rfs_date")}  />
                      </Mui.Grid>
                      <Mui.Grid item xs={5} className="innerForm">
                          <Mui.TextField label="Type Order" size="small" variant="outlined" className="inputField"  {...register("service_action_name")} />
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="Request By" size="small" variant="outlined" className="inputField"  {...register("requested_by_name")} />
                      </Mui.Grid>
                      <Mui.Grid item xs={4} className="innerForm">
                          <Mui.TextField label="Customer ID" size="small" variant="outlined" className="inputField"  {...register("customer_id")} />
                      </Mui.Grid>
                      <Mui.Grid item xs={8} className="innerForm">
                          <Mui.TextField label="Customer Name" size="small" variant="outlined" className="inputField"  {...register("customer_name")} />
                      </Mui.Grid>
                      <Mui.Grid item xs={4} className="innerForm">
                        &nbsp;
                      </Mui.Grid>
                      <Mui.Grid item xs={8} className="innerForm">
                          <Mui.TextField label="Customer Alias" size="small" variant="outlined" className="inputField"  {...register("alias")}  />
                      </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.TextField label="Contact / PIC" size="small" variant="outlined" className="inputField"  {...register("pic")} />
                              </Mui.Grid>
                                <Mui.Grid item xs={6} className="innerForm">
                                  <Mui.TextField label="Phone" type="number" size="small" variant="outlined" className="inputField" {...register("phone")} />
                              </Mui.Grid>
                              <Mui.Grid item xs={6} className="innerForm">
                                  <Mui.TextField label="Email" size="small" variant="outlined" className="inputField"  {...register("email")} />
                              </Mui.Grid>
                          
                      </Mui.Grid>

                      <Mui.Divider style={{margin:'20px 10px'}} />

                      <Mui.Grid container>
                            <Mui.Grid item xs={4} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                    <Mui.TextField label="Cluster" size="small" variant="outlined" className="inputField"  {...register("cluster")} />
                                  </Mui.FormControl>
                              </Mui.Grid>
                              
                              <Mui.Grid item xs={8} className="innerForm">
                                  <Mui.TextField label="Service Address" size="small" variant="outlined" className="inputField"  {...register("service_address")}  />
                              </Mui.Grid>
                              <Mui.Grid item xs={10} className="innerForm">
                                  <Mui.TextField label="Service Coordinate" size="small" variant="outlined" className="inputField"  {...register("location_coordinate")}  />
                              </Mui.Grid>
                              <Mui.Grid item xs={2} className="innerForm" textAlign={"right"}>
                                <Mui.Button
                                        component="label"
                                        variant="contained"
                                        color={"warning" }
                                        tabIndex={-1}
                                        startIcon={  <MuiIcon.Photo />}
                                        onClick={()=>{
                                          setBoxModal(true)
                                          setUrlFile(urlLoc)
                                        }}
                                      >
                                        {"View"}
                                  </Mui.Button>
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                    <Mui.TextField label="Region" size="small" variant="outlined" className="inputField"  {...register("region")}  />
                                  </Mui.FormControl>
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                    <Mui.TextField label="Regency" size="small" variant="outlined" className="inputField"  {...register("regency")}  />
                                  </Mui.FormControl>
                              </Mui.Grid>
                              <Mui.Grid item xs={4} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                    <Mui.TextField label="District" size="small" variant="outlined" className="inputField"  {...register("district")}  />
                                  </Mui.FormControl>
                              </Mui.Grid>
                              
                             
                             
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="Product" size="small" variant="outlined" className="inputField"  {...register("product")}  /> 
                                  </Mui.FormControl>
                              </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Divider style={{margin:'20px 10px'}} />
                      <Mui.Grid container>
                              <Mui.Grid item xs={6} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                <Mui.TextField label="ONT" size="small" variant="outlined" className="inputField"  {...register("ont_type")}  />
                                </Mui.FormControl>
                                
                              </Mui.Grid>
                              <Mui.Grid item xs={6} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="Serial Number ONU" size="small" variant="outlined" className="inputField"  {...register("sn_onu")} />
                                </Mui.FormControl>
                                
                              </Mui.Grid>
                              <Mui.Grid item xs={6} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="PPPOE User" size="small" variant="outlined" className="inputField"  {...register("pppoe_user")} />
                                </Mui.FormControl>
                              </Mui.Grid>
                              <Mui.Grid item xs={6} className="innerForm">
                                <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField label="PPPOE Password" size="small" variant="outlined" className="inputField"  {...register("pppoe_pass")} />
                                </Mui.FormControl>
                                
                              </Mui.Grid>
                      </Mui.Grid>
            </Mui.DialogContent>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerInfoServiceOrder;
