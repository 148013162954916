import React,{ useRef,useState } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"

import Header from '../lib/Header';
import MsgBox from '../lib/MsgBox';
import GetPayment from '../lib/GetPayment';
import Cstr from '../lib/ConvertString';
import DrawerRefundInvoice from '../drawer/DrawerRefundInvoice';
import DrawerCancelInvoice from '../drawer/DrawerCancelInvoice';


const Invoices = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [invoices,setInvoices] = React.useState([])
  const [checked,setChecked] = React.useState([])
  const [checkAll,setCheckAll] = React.useState(false)
  const [loading,setLoading] = React.useState(false)

  const cancelRef = useRef();
  const refundRef = useRef();
  const deleteRef = useRef();
  const formRef = useRef();
  const invoiceRef = useRef();
  const [error,setError] = useState([]);
  const [page,setPage] = React.useState(1)
  const { register, setValue, handleSubmit,reset } = useForm()
  const onFilter = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        status: data.status,
        perPage: data.perPage
        
      })
      getInvoices()
  }
  const onSubmit = (data) => {
    console.log(data)
  }
  const changePage = (event,value) => {
    setPage(value);
    getInvoices()
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    status: -1,
    perPage: 10
  })

  const checkAllFalse =()=>{
    const checkObject = [];
    for(var i=0; i<checked.length; i++){
      checkObject[i]={ id:checked[i].id, status:false}
    }
    setChecked(checkObject)
    setCheckAll(false)
  }
  const checkAllTrue =()=>{
      const checkObject = [];
      for(var i=0; i<checked.length; i++){
        checkObject[i]={ id:checked[i].id, status:true }
      }
      setChecked(checkObject)
      setCheckAll(true)
  }
  const checkAllAction = () => {
      console.log(checkAll)
      if(checkAll==false){
        checkAllTrue()
      }else{
        checkAllFalse()
      }
  };

  const afterRefund=()=>{
    getInvoices()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Refund Invoice',
      msg: 'Refund Invoice successfully.'
    })
  }

  const afterCancel=()=>{
    getInvoices()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Cancel Invoice',
      msg: 'Cancel Invoice successfully'
    })
  }
  
  const getInvoices = () => {
    setContentLoading(true)
    const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData());
      if(filter.status == -1){
        urlencoded.append("status[]",0);
        urlencoded.append("status[]",1);
        urlencoded.append("status[]",2);
        urlencoded.append("status[]",3);
      }else{
        urlencoded.append("status[]",filter.status);
      }
      urlencoded.append("search",filter.search);
      urlencoded.append("perPage", filter.perPage);
      urlencoded.append("page", page);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'invoice', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              console.log(result)
              const checkObject = [];
              for(var i=0; i < result.data.length; i++){
                checkObject[i]={id:result.data[i].id, status:false}
              }
              setChecked(checkObject)
              setInvoices(result.data)
              setPagination(result.pagination)
              setContentLoading(false)
            }else{
              setError(result.errors)
              setContentLoading(false)
            }
          })
          .then((error)=>{
            setContentLoading(false)
          })
  }

  React.useEffect(() => {
    getInvoices();
  }, [filter,page]); 

  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerRefundInvoice ref={refundRef} config={props.config} refreshRefund={afterRefund} />
      <DrawerCancelInvoice ref={cancelRef} config={props.config} refreshCancel={afterCancel} />
      
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Billing / Invoices ({pagination.totalRecords})
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />

            <Mui.Chip 
              label="Set Refund"
              color='error'
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.CreditCardOff />}
              onClick={ ()=> {
                refundRef.current.openForm(checked);
                //setRefund()
              }}
              />

            <Mui.Chip 
              label="Set Cancel"
              color='warning'
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.CancelPresentation />}
              onClick={ ()=> {
                cancelRef.current.openForm(checked);
                //setCancel()
              }}
              />
      </Mui.Toolbar>
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onFilter)}>
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="Status"
                          defaultValue={-1}
                          {...register("status")}
                      >
                          <Mui.MenuItem key={0} value={-1}>All</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={0}>Unpaid</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={1}>Paid</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={2}>Refund</Mui.MenuItem>
                          <Mui.MenuItem key={4} value={3}>Cancel</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={loading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
        ) : (
          <>
            <form ref={invoiceRef} style={{width:'100%'}} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Grid item xs={12} style={{margin:'20px'}}>
                <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <Mui.TableHead>
                        <Mui.TableRow>
                            <Mui.TableCell><Mui.Checkbox onChange={checkAllAction} /></Mui.TableCell>
                            <Mui.TableCell>ID</Mui.TableCell>
                            <Mui.TableCell>Template</Mui.TableCell>
                            <Mui.TableCell>CID</Mui.TableCell>
                            <Mui.TableCell>Customer</Mui.TableCell>
                            <Mui.TableCell>Product</Mui.TableCell>
                            <Mui.TableCell>Period</Mui.TableCell>
                            <Mui.TableCell>Due Date</Mui.TableCell>
                            <Mui.TableCell align="center">Amount</Mui.TableCell>
                            <Mui.TableCell align="center">Status</Mui.TableCell>
                        </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                    { invoices.map((invoice,key) => (
                        <Mui.TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <Mui.TableCell component="th" scope="row">
                                <Mui.Checkbox checked={checked[key].status} value={invoice.id} onChange={(event)=>{
                                      const checkObject = [];
                                      for(var i=0; i<checked.length; i++){
                                          if(i==key){
                                            checkObject[i] = {id: checked[i].id, status: event.target.checked}
                                          }else{
                                            checkObject[i] = checked[i]
                                          }
                                      }
                                      console.log(checkObject)
                                      setChecked(checkObject)
                                }} />
                            </Mui.TableCell>
                            <Mui.TableCell>{invoice.id}</Mui.TableCell>
                            <Mui.TableCell>{invoice.bill_name}</Mui.TableCell>
                            <Mui.TableCell>{invoice.customer_id}</Mui.TableCell>
                            <Mui.TableCell>{invoice.customer_name}</Mui.TableCell>
                            <Mui.TableCell>{invoice.product_name}</Mui.TableCell>
                            <Mui.TableCell>{new Date(invoice.cut_off).toLocaleString('default',{ month: 'short', year: 'numeric'})}</Mui.TableCell>
                            <Mui.TableCell>{new Date(invoice.due_date).toLocaleString('default',{ day: '2-digit', month: 'short', year: 'numeric'})}</Mui.TableCell>
                            <Mui.TableCell align="right">{Cstr('cur',invoice.nominal)}</Mui.TableCell>
                            <Mui.TableCell align="center"><GetPayment status={invoice.status} /></Mui.TableCell>
                            
                        </Mui.TableRow>
                                    )) }
                    </Mui.TableBody>
                </Mui.Table>
            </Mui.Grid>
            </form>
            <Mui.Grid item xs={12} >
                <Mui.Pagination 
                    sx={{ 
                      '& > .MuiPagination-ul': {
                        justifyContent: 'center',
                      },
                      p:2,
                    }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </div>
  );
}

export default Invoices;
