import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import * as MuiLab from '@mui/lab';
import { MuiFileInput } from 'mui-file-input';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';
import Region from '../Assets/region.json'

const DrawerReviewServiceOrder = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const childref = useRef();
    const formReject = useRef(null);
    const formActivate = useRef(null);
    const [action,setAction] = React.useState()
    const [loadingProcess,setLoadingProcess] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [rfs_date,setRfs_date] = React.useState()
    const [urlLoc,setUrlLoc] = React.useState()
    const [urlFile,setUrlFile] = React.useState()
    const [boxModal,setBoxModal] = React.useState(false)
    const [products,setProducts] = React.useState([]);
    const [productID, setProductID] = React.useState()
    const [cluster,setCluster] = React.useState([]);
    const [clusterID, setClusterID] = React.useState()
    const [region,setRegion] = React.useState(Region);
    const [selectedRegion, setSelectedRegion] = React.useState(16);
    const [regencies,setRegencies] = React.useState(Region[16].regencies);
    const [selectedRegency, setSelectedRegency] = React.useState(0);
    const [districts,setDistricts] = React.useState(Region[16].regencies[0].districts);
    const [selectedDistrict,setSelectedDistrict]= React.useState(0)
    const [villages,setVillages] = React.useState(Region[16].regencies[0].districts[0].villages)
    const [selectedVillage,setSelectedVillage] = React.useState(0)
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      id:'',
      order:[],
      taxo:[]
      });
    var obj;

    useImperativeHandle(ref, () => ({
      openForm: (id,order,taxo) => {
        setState({ isForm: true, id: id, order: order,taxo: taxo.ont})
        setAction(null)
        reset()
        setError([])
        console.log(taxo.ont)
        setClusterID(order.customer_service.cluster_id)
        setProductID(order.customer_service.product_id)
        setProducts(taxo.product)
        setCluster(taxo.cluster)
        setUrlLoc(order.customer_service.location_photo)
        setRfs_date(order.rfs_date)
        setValue('id',id)
        setValue('customer_service_id',order.customer_service_id)
        setValue('service_address',order.customer_service.service_address)
        setValue('location_coordinate',order.customer_service.location_coordinate)
        setValue('note',order.note)
        
        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }),[action]);

   const { register, setValue, handleSubmit,reset } = useForm()
   var obj;
   const onReject = (data) => {

     console.log('data')
     console.log(data)
     setLoadingProcess(true)
       
     const myHeaders = new Headers();
     myHeaders.append("Authorization",cookies.token);
     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

     const urlencoded = new URLSearchParams(new FormData(formReject.current));
     urlencoded.append("id", state.order.id);

    console.log(urlencoded)
     const requestOptions = {
       method: "POST",
       headers: myHeaders,
       body: urlencoded,
       redirect: "follow"
     };
     
     fetch(props.config.hostApi + '/service-order/reject', requestOptions)
         .then((response) => response.json())
         .then((result) => {
           console.log(result)
           obj = result
           if(obj.success){
              refreshParentOrder()
              setLoadingProcess(false)
           }else{

           console.log(obj)
             setError(obj.errors)
             setLoadingProcess(false)
             setisMsgBox({
               open: true,
               status: 'error',
               title:'Review Order',
               msg: obj.message,
             })
           }
         })
         .then(()=>{
           console.log(obj)
           setLoadingProcess(false)
         })
           
   }
   const onRespond = (id) => {
    setLoadingProcess(true)
      
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    
    console.log(urlencoded)
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    
    fetch(props.config.hostApi + '/service-order/response', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          obj = result
          if(obj.success){
            refreshParentOrder()
            setLoadingProcess(false)
          }else{
            setError(obj.errors)
            setLoadingProcess(false)
            setisMsgBox({
              open: true,
              status: 'error',
              title:'Review Order',
              msg: obj.message,
            })
          }
        })
        .then(()=>{
          console.log(obj)
          setLoadingProcess(false)
        })
          
  }
  const onActivate = (id) => {
    setLoadingProcess(true)
      
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams(new FormData(formActivate.current));
    urlencoded.append("id", state.order.id);
    
    console.log(urlencoded)
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    
    fetch(props.config.hostApi + '/service-order/activate', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          obj = result
          if(obj.success){
           refreshParentOrder()
            setLoadingProcess(false)
          }else{
            setError(obj.errors)
            setLoadingProcess(false)
            setisMsgBox({
              open: true,
              status: 'error',
              title:'Review Order',
              msg: obj.message,
            })
          }
        })
        .then(()=>{
          console.log(obj)
          setLoadingProcess(false)
        })
          
  }
  
  const test=(data)=>{
    console.log(data)
  }

  const refreshParentOrder = () => {
    props.refreshParentOrderUpdate()
    setState({
      isForm:false,
      id:'',
      order:[],
      taxo:[]
    })
  }
  
  const boxModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
        <Mui.Modal
            open={boxModal}
            onClose={()=>{setBoxModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Mui.Box sx={boxModalStyle}>
              <img src={urlFile} />
            </Mui.Box>
          </Mui.Modal>
          
            <Mui.Toolbar disableGutters style={{backgroundColor:"#eeeeee"}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Review Service &raquo; ( {state.id} )
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      //if(!loadingProcess && !loadingReject){ 
                        setState({openForm:false, id:'',order:[],taxo:[]})}
                      //}
                    }  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    variant="solid" 
                    loading={loadingProcess} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.NavigateNext />}
                    loadingPosition="start"
                    onClick={()=>{
                      if(action == 'reject'){
                        document.getElementById("buttonReject").click();
                      }else if(action == 'activate'){
                        document.getElementById("buttonActivate").click();
                      }else if(action == 'response'){
                        onRespond(state.order.id)
                      }
                    }}

                    > Submit
                  </MuiJoy.Button>
                  
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                  <Mui.Grid container>
                        <Mui.Grid item xs={12} className="innerForm"> 
                                  {state.order.response === 0 && state.order.status === 0 ? (
                                      <Mui.TextField 
                                      select
                                      label="Select Action" 
                                      size="small" 
                                      variant="outlined" 
                                      className="inputField"
                                      onChange={(event)=>{
                                        setAction(event.target.value)
                                        if(event.target.value == 'reject'){
                                          document.getElementById('rejection').style.display = 'block'
                                          document.getElementById('activation').style.display = 'none'
                                        }else if(event.target.value == 'activate'){
                                          document.getElementById('rejection').style.display = 'none'
                                          document.getElementById('activation').style.display = 'block'
                                        }else if(event.target.value == 'response'){
                                          document.getElementById('rejection').style.display = 'none'
                                          document.getElementById('activation').style.display = 'none'
                                        }else{
                                        }
                                        let fixHeight = window.innerHeight - 100
                                        setFormContentStyle({height: fixHeight + 'px'})
                                      }}  
                                      >
                                          <Mui.MenuItem key={0} value={'response'}><MuiIcon.TouchApp sx={{marginRight:'5px'}} color='primary' />Response</Mui.MenuItem>
                                          <Mui.MenuItem key={1} value={'reject'}><MuiIcon.Close sx={{marginRight:'5px'}} color='error' />Reject</Mui.MenuItem>
                                          <Mui.MenuItem key={2} value={'activate'}><MuiIcon.Check sx={{marginRight:'5px'}} color='success' /> Activate</Mui.MenuItem>
                                      
                                      </Mui.TextField>
                                    ) : (
                                      <Mui.TextField 
                                        select
                                        label="Select Action" 
                                        size="small" 
                                        variant="outlined" 
                                        className="inputField"
                                        onChange={(event)=>{
                                          setAction(event.target.value)
                                          if(event.target.value == 'reject'){
                                            document.getElementById('rejection').style.display = 'block'
                                            document.getElementById('activation').style.display = 'none'
                                          }else if(event.target.value == 'activate'){
                                            document.getElementById('rejection').style.display = 'none'
                                            document.getElementById('activation').style.display = 'block'
                                          }else{
                                            document.getElementById('rejection').style.display = 'none'
                                            document.getElementById('activation').style.display = 'none'
                                          }
                                          let fixHeight = window.innerHeight - 100
                                          setFormContentStyle({height: fixHeight + 'px'})
                                        }}  
                                        >
                                          <Mui.MenuItem key={1} value={'reject'}><MuiIcon.Close sx={{marginRight:'5px'}} color='error' />Reject</Mui.MenuItem>
                                          <Mui.MenuItem key={2} value={'activate'}><MuiIcon.Check sx={{marginRight:'5px'}} color='success' />Activate</Mui.MenuItem>
                                      
                                      </Mui.TextField>
                                    )
                                  }
                                  
                          </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container>
                        <Mui.Grid item xs={4} className="innerForm"> 
                              <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField
                                      select
                                      size="small"
                                      label="Cluster"
                                      disabled='true'
                                      defaultValue={clusterID}
                                  >
                                      {cluster.map((cluster,key) => (
                                      <Mui.MenuItem key={key} value={cluster.id}>{cluster.value}</Mui.MenuItem>
                                      ))}
                                  </Mui.TextField>
                              </Mui.FormControl>
                          </Mui.Grid>
                          
                          <Mui.Grid item xs={8} className="innerForm">
                              <Mui.TextField 
                                label="Service Address" 
                                size="small" 
                                variant="outlined" 
                                disabled='true'
                                className="inputField" 
                                {...register("service_address")}
                                />
                          </Mui.Grid>
                          <Mui.Grid item xs={9} className="innerForm">
                              <Mui.TextField 
                                  label="Service Coordinate" 
                                  size="small" 
                                  variant="outlined" 
                                  disabled='true'
                                  className="inputField" 
                                  {...register("location_coordinate")}
                                  />
                          </Mui.Grid>
                          <Mui.Grid item xs={3} className="innerForm" textAlign={"right"}>
                              <Mui.Button
                                      component="label"
                                      variant="contained"
                                      color={"warning" }
                                      tabIndex={-1}
                                      startIcon={  <MuiIcon.Photo />}
                                      onClick={()=>{
                                        setBoxModal(true)
                                        setUrlFile(urlLoc)
                                        console.log(urlLoc)
                                      }}
                                    >
                                      {"View"}
                                </Mui.Button>
                            </Mui.Grid>
                            <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.TextField 
                                        label="Region" 
                                        size="small" 
                                        variant="outlined" 
                                        disabled='true'
                                        defaultValue={
                                          region[selectedRegion].name + 
                                          ' / ' + 
                                          region[selectedRegion].regencies[selectedRegency].name +
                                          ' / ' + 
                                          region[selectedRegion].regencies[selectedRegency].districts[selectedDistrict].name +
                                          ' / ' +
                                          region[selectedRegion].regencies[selectedRegency].districts[selectedDistrict].villages[selectedVillage].name
                                        }
                                        className="inputField" />
                            </Mui.Grid>
                          
                        <Mui.Grid item xs={4} className="innerForm">
                            <input type="hidden" value={rfs_date}  />
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker label="RFS Date" 
                                          slotProps={{ textField: { size: 'small' } }}  
                                          format="YYYY-MM-DD" 
                                          defaultValue={dayjs(rfs_date)}
                                          disabled='true'
                                          onChange={(event)=>{
                                              setRfs_date(new Date(event).toLocaleDateString("sv-SE"))
                                              setValue("rfs_date", new Date(event).toLocaleDateString("sv-SE"))
                                          }} />
                            </LocalizationProvider>
                        </Mui.Grid>
                        <Mui.Grid item xs={8} className="innerForm">
                              <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField
                                      select
                                      size="small"
                                      label="Product"
                                      disabled="true"
                                      defaultValue={productID}
                                  >
                                      {products.map((product,key) => (
                                      <Mui.MenuItem key={key} value={product.id}>{product.value}</Mui.MenuItem>
                                      ))}
                                  </Mui.TextField>
                              </Mui.FormControl>
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField
                                      multiline
                                      size="small"
                                      rows={6}
                                      disabled="true"
                                      label="Note"
                                      {...register("note")}
                                    />
                              </Mui.FormControl>
                              {error.filter(error => error.path === 'note').length > 0 && <p className="error">{error.filter(error => error.path === 'note')[0].msg}</p>}
                          </Mui.Grid>
                          
                        
                  </Mui.Grid>
                  <Mui.Divider sx={{margin:'15px 10px'}} />
                  
                  {/*Reject */}
                  <Mui.Grid container id="rejection" sx={{display:'none'}}>
                    <form id="formReject" ref={formReject} onSubmit={handleSubmit(onReject)}>
                          
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField
                                      multiline
                                      size="small"
                                      rows={6}
                                      label="Rejection Note"
                                      {...register("activation_note")}
                                    />
                              </Mui.FormControl>
                              {error.filter(error => error.path === 'activation_note').length > 0 && <p className="error">{error.filter(error => error.path === 'activation_note')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Button 
                            id="buttonReject" 
                            sx={{display:'none'}} 
                            type="submit">&nbsp;</Mui.Button>
                    </form>
                  </Mui.Grid>
                  {/*Activation */}
                  <Mui.Grid container id="activation" sx={{display:'none'}}>
                    <form id="formActivate" ref={formActivate} onSubmit={handleSubmit(onActivate)}>
                        
                        <Mui.Grid item xs={12} className="innerForm">
                            <Mui.FormControl size="small" className="inputField">
                                <Mui.TextField 
                                  label="Circuit ID" 
                                  size="small" 
                                  variant="outlined" 
                                  className="inputField" 
                                  {...register("circuit_id")}  /> 
                            </Mui.FormControl>
                            {error.filter(error => error.path === 'circuit_id').length > 0 && <p className="error">{error.filter(error => error.path === 'circuit_id')[0].msg}</p>}
                          </Mui.Grid>
                        <Mui.Grid item xs={12} className="innerForm">
                            <Mui.FormControl size="small" className="inputField">
                                {<Mui.TextField 
                                  select
                                  label="ONU Type" 
                                  size="small" 
                                  variant="outlined" 
                                  className="inputField"
                                  {...register("ont_type")} >
                                    {state.taxo.map((ont,key) => (
                                    <Mui.MenuItem key={key} value={ont.id}>{ont.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>}
                              
                            </Mui.FormControl>
                            {error.filter(error => error.path === 'ont_type').length > 0 && <p className="error">{error.filter(error => error.path === 'ont_type')[0].msg}</p>}
                          </Mui.Grid>
                        <Mui.Grid item xs={12} className="innerForm">
                              <Mui.FormControl size="small" className="inputField">
                                <Mui.TextField 
                                  label="ONU Serial Number" 
                                  size="small" 
                                  variant="outlined" 
                                  className="inputField"
                                  {...register("sn_onu")}  />
                              </Mui.FormControl>
                              {error.filter(error => error.path === 'sn_onu').length > 0 && <p className="error">{error.filter(error => error.path === 'sn_onu')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.FormControl size="small" className="inputField">
                                  <Mui.TextField
                                      multiline
                                      size="small"
                                      rows={6}
                                      label="Activation Note"
                                      {...register("activation_note")}
                                    />
                              </Mui.FormControl>
                              {error.filter(error => error.path === 'activation_note').length > 0 && <p className="error">{error.filter(error => error.path === 'activation_note')[0].msg}</p>}
                          </Mui.Grid>

                          <Mui.Button 
                            id="buttonActivate" 
                            sx={{display:'none'}} 
                            type="submit">&nbsp;</Mui.Button>
                      </form>
                  </Mui.Grid>
            </Mui.DialogContent>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerReviewServiceOrder;
