import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { useForm, SubmitHandler } from "react-hook-form"
import MsgBox from '../lib/MsgBox';

const DrawerChpkgServiceOrder = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const childref = useRef();
    const formRef = useRef(null);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([]);
    const [rfs_date,setRfs_date] = React.useState()
    const [boxModal,setBoxModal] = React.useState(false)
    const [products,setProducts] = React.useState([]);
    const [productID, setProductID] = React.useState()
    const [formContentStyle,setFormContentStyle] = React.useState({height:'300px'})
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const [state, setState] = React.useState({
      isForm:false,
      submitForm: false,
      subscription_date: ''
      });
    var obj;

    useImperativeHandle(ref, () => ({
      openForm: (id,order,taxo) => {
        setState({ isForm: true, id: id, order: order,taxo: taxo})
        setProductID(order.product_id)
        setProducts(taxo.product)
        setValue("customer_service_id",id)
        //Set Height Dialog Component
        let fixHeight = window.innerHeight - 100
        setFormContentStyle({height: fixHeight + 'px'})
      },
      
   }));

   const { register, setValue, handleSubmit,reset } = useForm()
   var obj;
   const onSubmit = (data) => {
     console.log('data')
     console.log(data)
     setLoading(true)
       
     const myHeaders = new Headers();
     myHeaders.append("Authorization",cookies.token);
     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

     const urlencoded = new URLSearchParams(new FormData(formRef.current));

    console.log(urlencoded)
     const requestOptions = {
       method: "POST",
       headers: myHeaders,
       body: urlencoded,
       redirect: "follow"
     };
     
     fetch(props.config.hostApi + '/service-order/change', requestOptions)
         .then((response) => response.json())
         .then((result) => {
           obj = result
           if(obj.success){
            refreshParentOrder()
             setLoading(false)
           }else{
             setError(obj.errors)
             setLoading(false)
             setisMsgBox({
               open: true,
               status: 'error',
               title:'Review Order',
               msg: obj.message,
             })
           }
         })
         .then(()=>{
           console.log(obj)
           setLoading(false)
         })
           
   }
  const refreshParentOrder = () => {
    props.refreshParentChpkg()
    setState({isForm:false})
  }
  
  const boxModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm}  PaperProps={{sx: { width: "90%", maxWidth: "800px", overflow: 'hidden' },}}>
        
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{backgroundColor:"#eeeeee"}} className="formHeader"> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Order Change Package Service &raquo; ( {state.id} )
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <MuiJoy.Button
                    variant="solid"
                    onClick={()=> {
                      if(!loading){ setState({isAddFormOpen:false})}
                    }}  
                    startDecorator={<MuiIcon.Replay/>}
                  > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.PublishedWithChanges />}
                    loadingPosition="start"
                    > Change
                  </MuiJoy.Button>
                  
            </Mui.Toolbar>
            <Mui.DialogContent sx={formContentStyle} className="formContent">
                  <input type="hidden" {...register("customer_service_id", { required: true })} />
                          <Mui.Grid container>
                          <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="Current Service Package"
                                          defaultValue={productID}
                                      >
                                          {products.filter(product => product.id === productID).map((product,key) => (
                                          <Mui.MenuItem key={key} value={product.id}>{product.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                              </Mui.Grid>
                              
                            <Mui.Grid item xs={4} className="innerForm">
                                <input type="hidden" value={rfs_date}  />
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker label="RFS Date" 
                                              slotProps={{ textField: { size: 'small' } }}  
                                              format="YYYY-MM-DD" 
                                              defaultValue={dayjs(rfs_date)}
                                              onChange={(event)=>{
                                                  setRfs_date(new Date(event).toLocaleDateString("sv-SE"))
                                                  setValue("rfs_date", new Date(event).toLocaleDateString("sv-SE"))
                                              }} />
                                </LocalizationProvider>
                            </Mui.Grid>
                            <Mui.Grid item xs={8} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          select
                                          size="small"
                                          label="New Package"
                                          defaultValue={productID}
                                          {...register("product_id")}
                                      >
                                          {products.map((product,key) => (
                                          <Mui.MenuItem key={key} value={product.id}>{product.value}</Mui.MenuItem>
                                          ))}
                                      </Mui.TextField>
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'product_id').length > 0 && <p className="error">{error.filter(error => error.path === 'product_id')[0].msg}</p>}
                              </Mui.Grid>
                              <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.FormControl size="small" className="inputField">
                                      <Mui.TextField
                                          multiline
                                          size="small"
                                          rows={6}
                                          label="Note"
                                          {...register("note")}
                                       />
                                  </Mui.FormControl>
                                  {error.filter(error => error.path === 'note').length > 0 && <p className="error">{error.filter(error => error.path === 'note')[0].msg}</p>}
                              </Mui.Grid>
                              
                            
                      </Mui.Grid>
                  
            </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerChpkgServiceOrder;
