import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiBase  from '@mui/base';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from "../lib/MsgBox";

import { useForm, SubmitHandler } from "react-hook-form"


const DrawerEditDeviceType = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    var obj;
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));
      urlencoded.append("object", 'vendor_product_type');

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      fetch(props.config.hostApi + 'taxo/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(obj.success){
              refreshDeviceTypes()
              loadingOff()
            }else{
              setError(obj.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Update Customer',
                msg: obj.message,
              })
            }
          })
          .then(()=>{
            console.log('disini')
            console.log(obj)
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      taxo:[]
    });

    useImperativeHandle(ref, () => ({
      openForm: (taxo) => {
        setState({ isForm: true, taxo: taxo})
        setValue("id", taxo.id)
        setValue("sort", taxo.sort)
        setValue("value", taxo.value)
        setValue("desc", taxo.desc)
      }
   }));

   const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
  const refreshDeviceTypes = () => {
    props.refreshUpdate()
    setState({isForm:false,taxo:[]})
  }


  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} /*onClose={ ()=>{setState({isForm:false,id:'',device:[],taxo:[]})}}*/ PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>

      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Edit DeviceType &raquo; ( {state.taxo.id} )
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({isForm:false,id:'',taxo:[],olt:[]})}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
              <MuiJoy.Button
                type="submit"
                variant="solid" 
                loading={loading} 
                sx={{ margin: '0px 3px'}}
                startDecorator={<MuiIcon.Save />}
                loadingPosition="start"
                > Update
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
                <input type="hidden" {...register("id", { required: true })} />
                {error.filter(error => error.path === 'id').length > 0 && <p className="error">{error.filter(error => error.path === 'id')[0].msg}</p>}
                <Mui.Grid container>
                    <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                        <span>* Require Field</span>
                    </Mui.Grid>
                    <Mui.Grid item xs={4} className="innerForm" >
                          <Mui.TextField 
                            label="Sort" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                              {...register("sort")} 
                          />
                          {error.filter(error => error.path === 'sort').length > 0 && <p className="error">{error.filter(error => error.path === 'sort')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={8} className="innerForm">
                          <Mui.TextField label="*DeviceType Name" size="small" variant="outlined" className="inputField" 
                              {...register("value")} 
                          />
                          {error.filter(error => error.path === 'value').length > 0 && <p className="error">{error.filter(error => error.path === 'value')[0].msg}</p>}
                      </Mui.Grid>
                      
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="*Command Action" size="small" variant="outlined" className="inputField" 
                              {...register("desc")} 
                          />
                              {error.filter(error => error.path === 'desc').length > 0 && <p className="error">{error.filter(error => error.path === 'desc')[0].msg}</p>}
                      </Mui.Grid>
                      
                </Mui.Grid>
        </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerEditDeviceType;
