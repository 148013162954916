export default function ConvertString(type, value) {
  if(type=='cur'){
      return (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(value)
      )
  }else if(type=='num'){
    return (
      new Intl.NumberFormat('id-ID', {  minimumFractionDigits: 0 }).format(value)
    )
  }
}