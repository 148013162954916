import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiChart from '@mui/x-charts';
import Header from '../lib/Header';
import Cstr from '../lib/ConvertString';

const Dashboard = (props) => {
  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [data,setData] = useState({
    xLabels :[],
    pieChartServiceCustomer:[],
    lineChartSOPropgress:[],
    lineChartSOStatus:[],
    pieChartLastMonthInvoice: [],
    pieChartLastMonthInvoiceAmount: [],
    listLastPayment: [],
  })

  const data1 ={
    xLabels :[
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Des'
    ],
    "pieChartServiceCustomer":[
      {"label":"Terminated","value":3,"color":"#F8006A"},
      {"label":"Activated","value":5,"color":"#45B500"},
      {"label":"Suspended","value":1,"color":"#F87E00"}
      ],
    lineChartSOPropgress:[
      { data: [400, 300, 200, 280, 189, 230, 340], label: 'On Request', color:'#1876D2' },
      { data: [240, 138, 980, 398, 480, 380, 430], label: 'Out of RFS',color:'#F87E00' },
      { data: [140, 108, 90, 98, 80, 80, 43], label: 'Rejected',color:'#F80000' },
      { data: [300, 200, 145, 213, 199, 130, 211], label: 'Done',color:'#45B500' },
    ],
    lineChartSOStatus:[
      { data: [400, 300, 200, 280, 189, 230, 340], label: 'New Service', color:'#1876D2' },
      { data: [240, 138, 980, 398, 480, 380, 430], label: 'Upgrade',color:'#F87E00' },
      { data: [140, 108, 90, 98, 80, 80, 43], label: 'Downgrade',color:'#F80000' },
      { data: [300, 200, 145, 213, 199, 130, 211], label: 'Terminate',color:'#45B500' },
    ],
    pieChartLastMonthInvoice: [
      { value: 10, label: 'Paid',color:'#45B500' },
      { value: 15, label: 'Outstanding', color:'#F87E00' },
    ],
    pieChartLastMonthInvoiceAmount: [
      { value: 1000000, label: 'Paid',color:'#45B500' },
      { value: 150000, label: 'Outdtanding', color:'#F87E00' },
    ],
    listLastPayment: [
      {"cut_off": "2024-05-15",
      "due_date": "2024-05-25",
      "customer_service_id": 3,
      "customer_id": 3,
      "customer_name": "CustomerName17u",
      "customer_alias": "CustomerAlias17u",
      "customer_pic": "PIC17u",
      "customer_phone": "0812345678170",
      "customer_email": "test17u@mail.com",
      "sales_name": "Super Admin1",
      "cluster_name": "Cluster 3",
      "product_name": "Paket-1-150M",
      "product_price": 1500000,
      "nominal": 1500000,
      "payment_date": null,
      "suspend": 0,
      "unsuspend": 0,
      "status": 2,
      "account_id": 1000},

      {"cut_off": "2024-05-15",
      "due_date": "2024-05-25",
      "customer_service_id": 3,
      "customer_id": 3,
      "customer_name": "CustomerName17u",
      "customer_alias": "CustomerAlias17u",
      "customer_pic": "PIC17u",
      "customer_phone": "0812345678170",
      "customer_email": "test17u@mail.com",
      "sales_name": "Super Admin1",
      "cluster_name": "Cluster 3",
      "product_name": "Paket-1-150M",
      "product_price": 1500000,
      "nominal": 1500000,
      "payment_date": null,
      "suspend": 0,
      "unsuspend": 0,
      "status": 2,
      "account_id": 1000},
    ],
  }
  const getData = () => {
    setContentLoading(true)
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

   fetch(props.config.hostApi + 'dashboard', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.success){
            console.log(result.data.pieChartServiceCustomer)
            console.log(data1.pieChartServiceCustomer)
            setData(result.data)
            setContentLoading(false)
        }else{
            setContentLoading(false)
        }
      })
      .then((error)=>{
        console.log(error)
      })
      
      
}
React.useEffect(() => {
  getData()
  const profiles = ['profile1', 'profile2', 'profile3'];
  let obj = {};

  profiles.forEach(profile => obj[profile] = profile);

  console.log(obj);
}, []); 

  return (
    <div>
      <Header config={props.config} />
      <Mui.Grid className='topBar' container style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
        <Mui.Grid item md={12}>
          <Mui.Toolbar disableGutters>
              <Mui.Breadcrumbs aria-label="breadcrumb">
                  <Mui.Link
                    underline="none"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                  >
                    <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                  </Mui.Link>
                  
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    Dashboard
                  </Mui.Typography>
              </Mui.Breadcrumbs>
              
          </Mui.Toolbar>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={2}>
      {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
          ) : (
              <>
              <Mui.Grid item xs={4}>
                <Mui.Box style={{padding:'20px'}}>
                      <Mui.Typography>Service Customer</Mui.Typography>
                      <Mui.Divider sx={{margin: '20px 0'}} />
                      <MuiChart.PieChart
                        series={[
                          {
                            data: data.pieChartServiceCustomer
                          },
                        ]}
                        width={400}
                        height={200}
                      />
                  </Mui.Box>
              </Mui.Grid>
              
              <Mui.Grid item xs={8}>
                <Mui.Box style={{padding:'20px'}}>
                      <Mui.Typography>Service Order</Mui.Typography>
                      <Mui.Divider sx={{margin: '20px 0'}} />
                      <MuiChart.LineChart
                          width={700}
                          height={200}

                          series={data.lineChartSOPropgress}
                          xAxis={[{ scaleType: 'point', data: data.xLabels }]}
                        />
                  </Mui.Box>
              </Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.Box style={{padding:'20px'}}>&nbsp;</Mui.Box>
              </Mui.Grid>
              <Mui.Grid item xs={8}>
                <Mui.Box style={{padding:'20px'}}>
                      <MuiChart.LineChart
                          width={700}
                          height={200}

                          series={data.lineChartSOStatus}
                          xAxis={[{ scaleType: 'point', data: data.xLabels }]}
                        />
                  </Mui.Box>
              </Mui.Grid>
              <Mui.Grid item xs={4}>
                  <Mui.Grid item xs={12}>
                    <Mui.Box style={{padding:'20px'}}>
                          <Mui.Typography>Last Month Invoice</Mui.Typography>
                          <Mui.Divider sx={{margin: '20px 0'}} />
                          <MuiChart.PieChart
                            series={[
                              {
                                data: data.pieChartLastMonthInvoice,
                              },
                            ]}
                            width={400}
                            height={200}
                          />
                      </Mui.Box>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                    <Mui.Box style={{padding:'20px'}}>
                          <Mui.Typography>Last Month Invoice Amount</Mui.Typography>
                          <Mui.Divider sx={{margin: '20px 0'}} />
                          <MuiChart.PieChart
                            series={[
                              {
                                data: data.pieChartLastMonthInvoiceAmount
                              },
                            ]}
                            width={400}
                            height={200}
                          />
                      </Mui.Box>
                    </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid item xs={8}>
                <Mui.Box style={{padding:'20px'}}>
                      <Mui.Typography>Payment History</Mui.Typography>
                      <Mui.Divider sx={{margin: '20px 0'}} />
                      <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>Date</Mui.TableCell>
                                <Mui.TableCell>Product</Mui.TableCell>
                                <Mui.TableCell>CID</Mui.TableCell>
                                <Mui.TableCell>SID</Mui.TableCell>
                                <Mui.TableCell>Customer Name</Mui.TableCell>
                                <Mui.TableCell>Sales</Mui.TableCell>
                                <Mui.TableCell align="right">Amount</Mui.TableCell>
                                <Mui.TableCell align="center">Period</Mui.TableCell>
                                <Mui.TableCell align="center">Due Date</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { data.listLastPayment.map((listLastPayment,key) => (
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                
                                <Mui.TableCell>{listLastPayment.payment_date}</Mui.TableCell>
                                <Mui.TableCell>{listLastPayment.product_name}</Mui.TableCell>
                                <Mui.TableCell>{listLastPayment.customer_id}</Mui.TableCell>
                                <Mui.TableCell>{listLastPayment.customer_service_id}</Mui.TableCell>
                                <Mui.TableCell>{listLastPayment.customer_name}</Mui.TableCell>
                                <Mui.TableCell>{listLastPayment.sales_name}</Mui.TableCell>
                                <Mui.TableCell align="right">{Cstr('cur',listLastPayment.nominal)}</Mui.TableCell>
                                <Mui.TableCell align="center">{new Date(listLastPayment.cut_off).toLocaleString('default',{ month: 'short', year: 'numeric'})}</Mui.TableCell>
                                <Mui.TableCell align="center">{listLastPayment.due_date}</Mui.TableCell>
                                
                            </Mui.TableRow>
                                        )) }
                        </Mui.TableBody>
                    </Mui.Table>
                      
                  </Mui.Box>
              </Mui.Grid>
                          
              </>
          )}
    </Mui.Grid>
    </div>
  );
}

export default Dashboard;



