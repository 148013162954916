import React,  { useRef,forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';
import MsgBox from "../lib/MsgBox";
import { useForm, SubmitHandler } from "react-hook-form"


const DrawerAddTemplate = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      console.log(props.config.hostApi)
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'invoice/template/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            if(result.success){
              refreshParent()
              loadingOff()
              reset()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Save Template',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            console.log(error)
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false
    });

    useImperativeHandle(ref, () => ({
      openForm: () => {
        setState({ 
          isForm: true
         })
      }
   }));

    const loadingOn = () =>{
      setLoading(true)
    }
    const loadingOff = () =>{
      setLoading(false)
    }
    const refreshParent = () => {
      props.refreshSave()
      setState({
        isForm:false
      })
    }
  React.useEffect(() => {
    

  }); 

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Add New Template
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  
                  <Mui.Button
                    variant="contained"
                    disabled={state.loading}
                    onClick={()=> setState({isForm:false,
                      olt:[],
                      bng:[],
                      vendor:[],
                      vendor_product_type:[],
                      snmp_version:[],
                      remote_protocol:[]
                    })}    
                    sx={{ margin: '0px 3px' }}
                  >
                      <MuiIcon.Replay/> Back 
                  </Mui.Button>
                  <MuiLab.LoadingButton
                    type="submit"
                    loading={state.loading}
                    disabled={state.loading}
                    loadingPosition="start"
                    variant="contained"  
                    sx={{ margin: '0px 3px'}}
                    startIcon={<MuiIcon.Save />} 
                    > Save
                  </MuiLab.LoadingButton>
            </Mui.Toolbar>
            <Mui.DialogContent>
                    <Mui.Grid container>
                        <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                            <span>* Require Field</span>
                        </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                              <Mui.TextField label="*Name" size="small" variant="outlined" className="inputField" {...register("bill_name")} />
                              {error.filter(error => error.path === 'bill_name').length > 0 && <p className="error">{error.filter(error => error.path === 'bill_name')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={3} className="innerForm">
                                <Mui.FormControl sx={{ width: '100%' }}>
                                        <Mui.TextField
                                              select
                                              size="small"
                                              label="*Recurring"
                                              {...register("recurring")} 
                                          >
                                              <Mui.MenuItem key={0} value={1}>{'Yes'}</Mui.MenuItem>
                                              <Mui.MenuItem key={1} value={0}>{'No'}</Mui.MenuItem>
                                          </Mui.TextField>
                                      </Mui.FormControl>
                              {error.filter(error => error.path === 'recurring').length > 0 && <p className="error">{error.filter(error => error.path === 'recurring')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={3} className="innerForm">
                                <Mui.FormControl sx={{ width: '100%' }}>
                                        <Mui.TextField
                                              select
                                              size="small"
                                              label="*Cut Off Date"
                                              {...register("cut_off")} 
                                          >
                                                {(() => {
                                                    const count = 31
                                                    const arr = []
                                                    for (let i = 0; i < count; i++) {
                                                        arr.push(
                                                          <Mui.MenuItem key={i} value={i+1}>{i+1}</Mui.MenuItem>
                                                        );
                                                    }
                                                    return arr;
                                                })()}
                                              
                                          </Mui.TextField>
                                      </Mui.FormControl>
                              {error.filter(error => error.path === 'recurring').length > 0 && <p className="error">{error.filter(error => error.path === 'recurring')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={3} className="innerForm">
                                <Mui.FormControl sx={{ width: '100%' }}>
                                        <Mui.TextField
                                              select
                                              size="small"
                                              label="*Due Date"
                                              {...register("due_date")} 
                                          >
                                                {(() => {
                                                    const count = 31
                                                    const arr = []
                                                    for (let i = 0; i < count; i++) {
                                                        arr.push(
                                                          <Mui.MenuItem key={i} value={i+1}>{i+1}</Mui.MenuItem>
                                                        );
                                                    }
                                                    return arr;
                                                })()}
                                              
                                          </Mui.TextField>
                                      </Mui.FormControl>
                              {error.filter(error => error.path === 'recurring').length > 0 && <p className="error">{error.filter(error => error.path === 'recurring')[0].msg}</p>}
                          </Mui.Grid>
                          <Mui.Grid item xs={3} className="innerForm">
                                <Mui.FormControl sx={{ width: '100%' }}>
                                        <Mui.TextField
                                              select
                                              size="small"
                                              label="*Duration"
                                              {...register("period")} 
                                          >
                                                {(() => {
                                                    const count = 31
                                                    const arr = []
                                                    for (let i = 0; i < count; i++) {
                                                        arr.push(
                                                          <Mui.MenuItem key={i} value={i+1}>{i+1}</Mui.MenuItem>
                                                        );
                                                    }
                                                    return arr;
                                                })()}
                                              
                                          </Mui.TextField>
                                      </Mui.FormControl>
                              {error.filter(error => error.path === 'recurring').length > 0 && <p className="error">{error.filter(error => error.path === 'recurring')[0].msg}</p>}
                          </Mui.Grid>
                    </Mui.Grid>
                
            </Mui.DialogContent>
          </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddTemplate;
