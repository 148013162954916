import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiBase  from '@mui/base';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import CurrencyInput from 'react-currency-input-field';
import MsgBox from "../lib/MsgBox";
import { useForm, SubmitHandler } from "react-hook-form"


const DrawerAddProduct = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [price,setPrice] = React.useState(0)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    var obj;
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'product/create', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            obj = result
            if(obj.success){
              refreshProducts()
              loadingOff()

              //Reset Form
              reset()
              setPrice(0)
              setError([])
              
            }else{
              setError(obj.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Save Product',
                msg: obj.message,
              })
            }
          })
          .then(()=>{
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      radius:[],
      billTemplate:[]
    });

    useImperativeHandle(ref, () => ({
      openForm: (radius,billTemplate) => {
        setState({ isForm: true, radius: radius,billTemplate: billTemplate})
        console.log(billTemplate)
      }
   }));

   const loadingOn = () =>{
    console.log('Loading On')
    setLoading(true)
  }
  const loadingOff = () =>{
    console.log('LOading Off')
    setLoading(false)
  }
  const refreshProducts = () => {
    props.refreshProductSave()
    setState({isForm:false,radius:[],billTemplate:[]})
  }


  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>

      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Add New Product
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({isForm:false,radius:[],billTemplate:[]})}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
              <MuiJoy.Button
                type="submit"
                variant="solid" 
                loading={loading} 
                sx={{ margin: '0px 3px'}}
                startDecorator={<MuiIcon.Save />}
                loadingPosition="start"
                > Save
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
                <Mui.Grid container>
                    <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                        <span>* Require Field</span>
                    </Mui.Grid>
                    <Mui.Grid item xs={3} className="innerForm" >
                          <Mui.TextField 
                            label="Sort" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                              {...register("sort")} 
                          />
                          {error.filter(error => error.path === 'sort').length > 0 && <p className="error">{error.filter(error => error.path === 'sort')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={5} className="innerForm">

                          <Mui.FormControl size="small" className="inputField">
                            <Mui.TextField
                                select
                                size="small"
                                label="*Billing Template"
                                {...register("template_id")} 
                            >
                                {state.billTemplate.map((bill,key) => (
                                <Mui.MenuItem key={key} value={bill.id}>{bill.bill_name}</Mui.MenuItem>
                                ))}
                            </Mui.TextField>
                        </Mui.FormControl>
                        {error.filter(error => error.path === 'template_id').length > 0 && <p className="error">{error.filter(error => error.path === 'template_id')[0].msg}</p>}
                      
                      </Mui.Grid>
                      <Mui.Grid item xs={4} className="innerForm">

                          <Mui.FormControl size="small" className="inputField">
                            <Mui.TextField
                                select
                                size="small"
                                label="*Radius"
                                {...register("device_id")} 
                            >
                                {state.radius.map((rad,key) => (
                                <Mui.MenuItem key={key} value={rad.id}>{rad.hostname}</Mui.MenuItem>
                                ))}
                            </Mui.TextField>
                        </Mui.FormControl>
                        {error.filter(error => error.path === 'device_id').length > 0 && <p className="error">{error.filter(error => error.path === 'device_id')[0].msg}</p>}
                      
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField label="*Product Name" size="small" variant="outlined" className="inputField" 
                              {...register("product_name")} 
                          />
                          {error.filter(error => error.path === 'product_name').length > 0 && <p className="error">{error.filter(error => error.path === 'product_name')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={6} className="innerForm">
                          <input type="hidden" {...register("product_price")} value={price}/>
                          <CurrencyInput size="small" variant="outlined" className="inputField"
                                label="*Price" 
                                customInput={Mui.TextField}
                                prefix="Rp. "
                                defaultValue={price}
                                decimalsLimit={0}
                                onValueChange={(value, name, values) => setPrice(value)}
                          />
                            {error.filter(error => error.path === 'product_price').length > 0 && <p className="error">{error.filter(error => error.path === 'product_price')[0].msg}</p>}
                      </Mui.Grid>
                      
                      <Mui.Grid item xs={6} className="innerForm">
                          <Mui.TextField label="*Max Speed (mbps)" size="small" variant="outlined" className="inputField" 
                          {...register("product_limit")} />
                          {error.filter(error => error.path === 'product_limit').length > 0 && <p className="error">{error.filter(error => error.path === 'product_limit')[0].msg}</p>}
                      </Mui.Grid>
                      <Mui.Grid item xs={12} className="innerForm">
                          <Mui.TextField 
                              label="Radius Profile Name" 
                              size="small"
                              variant="outlined" 
                              className="inputField" 
                              {...register("desc")} />
                              {error.filter(error => error.path === 'desc').length > 0 && <p className="error">{error.filter(error => error.path === 'desc')[0].msg}</p>}
                      </Mui.Grid>
                      
                </Mui.Grid>
        </Mui.DialogContent>
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddProduct;
