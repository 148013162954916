import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import DrawerProfile from '../drawer/DrawerProfile';
import MsgBox from '../lib/MsgBox';

const Header = (props) => {
    const [subProduct, setSubProduct] = React.useState();
    const openSubProduct = Boolean(subProduct);
    const [subBilling, setSubBilling] = React.useState();
    const openSubBilling = Boolean(subBilling);

    const [cookie,setCookie] = useCookies(['bas_']);
    const [isMsgBox, setisMsgBox] = React.useState({})
    const [isSalesOpen, setIsSalesOpen] = useState(false)
    const ProfileRef = useRef();

    const handleClickSubProduct = (event) => {
        setSubProduct(event.currentTarget);
    };
    const handleCloseSubProduct = () => {
        setSubProduct(null);
    };

    const handleClickSubBilling = (event) => {
        setSubBilling(event.currentTarget);
    };
    const handleCloseSubBilling = () => {
        setSubBilling(null);
    };


    const [anchorEl, setAnchorEl] = React.useState();
    const open = Boolean(anchorEl);

    const afterSave =()=>{

    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const afterUpdate=()=>{
        setisMsgBox({
          open: true,
          status: 'success',
          title:'Update Profile',
          msg: 'Update Profile successfully'
        })
      }
    
    React.useEffect(() => {
        if(cookie.profile){
            ProfileRef.current.openForm(props.config);
            setCookie('profile',false)
            afterUpdate()
        }
      }, []);

  return (
    <div>
        <MsgBox config={isMsgBox} />
        <DrawerProfile ref={ProfileRef} config={props.config} refreshProductSave={afterSave} />
        <Mui.AppBar position="fixed">
            <Mui.Toolbar disableGutters>
                <MuiIcon.DataObject sx={{ m: 2 }} style={{fontSize:'36'}} />
                <Mui.Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/dashboard"
                    sx={{
                    mr: 2,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                >
                    NetMS 
                </Mui.Typography>
                <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                <Mui.Button variant='text' color='inherit' sx={{m:1}} href='/dashboard'>Dashboard</Mui.Button>
                <Mui.Button variant='text' color='inherit' sx={{minHeight: 0, minWidth: 0, padding: 1}} onClick={handleClickSubProduct}>Data</Mui.Button>
                <Mui.Button variant='text' color='inherit' sx={{m:1}} href='/serviceorder'>Service Order</Mui.Button>
                <Mui.Button variant='text' color='inherit' sx={{m:1}} href='/customers'>Service Customer</Mui.Button>
                <Mui.Button variant='text' color='inherit' sx={{minHeight: 0, minWidth: 0, padding: 1}} onClick={handleClickSubBilling}>Billing</Mui.Button>
                <Mui.Button color='inherit' sx={{minHeight: 0, minWidth: 0, padding: 1}} onClick={handleClick}><MuiIcon.Settings fontSize="small" /></Mui.Button>

                <Mui.Button color='inherit' sx={{minHeight: 0, minWidth: 0, padding: 1}} 
                onClick={()=> {
                    ProfileRef.current.openForm(props.config);
                }}><MuiIcon.AccountCircle fontSize="small" /></Mui.Button>
                <Mui.Button color='inherit' href='/signout' sx={{minHeight: 0, minWidth: 0, padding: 1}}><MuiIcon.Logout fontSize="small" /></Mui.Button>
                
                <Mui.Menu
                    anchorEl={subProduct}
                    id="sub-data"
                    open={openSubProduct}
                    onClose={handleCloseSubProduct}
                    onClick={handleCloseSubProduct}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <Mui.StepLabel className='menuLabel'>Products</Mui.StepLabel>
                    <Mui.Link href="/products" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem onClick={ ()=> setIsSalesOpen(true)}>
                            <Mui.ListItemIcon>
                                <MuiIcon.LeakAdd fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Products</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    <Mui.Link href="/clusters" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem onClick={ ()=> setIsSalesOpen(true)}>
                            <Mui.ListItemIcon>
                                <MuiIcon.Mediation fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Clusters</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    <Mui.StepLabel className='menuLabel'>Devices</Mui.StepLabel>

                    <Mui.Link href="/radius" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.Spoke fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>RADIUS</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    
                    <Mui.Link href="/BNG" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.Storage fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>BNG</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>

                    <Mui.Link href="/olt" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.SettingsInputComponent fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>OLT</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    
                </Mui.Menu>

                <Mui.Menu
                    anchorEl={subBilling}
                    id="sub-data"
                    open={openSubBilling}
                    onClose={handleCloseSubBilling}
                    onClick={handleCloseSubBilling}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    <Mui.Link href="/billing-templates" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.Pix fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Templates</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    <Mui.Link href="/invoices" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.ReceiptLong fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Invoices</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    
                </Mui.Menu>

                <Mui.Menu
                    anchorEl={anchorEl}
                    id="sub-data"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    
                    
                    <Mui.Link href="/device-type" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.Android fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Device Type</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    <Mui.Link href="/users" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.Group fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Users</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                    <Mui.Link href="/taxo" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.TravelExplore fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Taxonomy</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>
                </Mui.Menu>
            </Mui.Toolbar>
        </Mui.AppBar>
    </div>
  );
}

export default Header;
