import React,{ useRef,useState } from 'react';
import * as Mui  from '@mui/material';

export default function GetPayment(props) {
  
  const statusPayment = (_status) => {
    switch(_status) { 
      case 0: {
        //Unpaid
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={'Unpaid'}  
            color="warning"
          />
          ) 
          break;
         
      } 
      case 1: {
        //Paid
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
            label={'Paid'} 
            color="success"
          /> 
          )
         break; 
      } 
      case 2: { 
        //Refund
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={'Refund'}  
            color="default"
          />
          ) 
         break; 
      } 
      case 3: { 
        //Cancel
        return( 
          <Mui.Chip sx={{minWidth:'90px'}}
          label={"Cancel"}  
            color="default"
          />
          ) 
         break; 
      } 
      
   } 
   
  }
  
  return statusPayment(props.status);
}
