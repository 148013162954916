import React,{ useRef,useState } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"

import Header from '../lib/Header';
import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import DrawerEditUser from '../drawer/DrawerEditUser';
import DrawerDeleteUser from '../drawer/DrawerDeleteUser';
import DrawerAddUser from '../drawer/DrawerAddUser';



const Users = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [loading,setLoading] = React.useState(false)

  const addRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();

  const [taxo,setTaxo] = useState([]);
  const [users,setUsers] = useState([]);
  const [perpage,setPerpage] = React.useState()
  
  const { register, setValue, handleSubmit,reset } = useForm()
  
  const [page,setPage] = React.useState()

  const changePage = (event,value) => {
    setPage(value);
    getUsers()
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    service_action: '',
    status:'',
    so_date_start: '',
    so_date_end: '',
    perPage: 10

  })
  var obj;
  const onSubmit = (data) => {
    console.log(data)
  }


  const afterSave=()=>{
    getUsers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New User',
      msg: 'Saving new User successfully.'
    })
  }

  const afterDelete=()=>{
    getUsers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete User',
      msg: 'Delete User successfully'
    })
  }
  const afterUpdate=()=>{
    getUsers()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update User',
      msg: 'Update User successfully'
    })
  }

  const getUsers = () => {
    setContentLoading(true)
    console.log('getUsers')
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("search", filter.search);
    urlencoded.append("perPage", filter.perPage);
    urlencoded.append("page", filter.page);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    
    fetch(props.config.hostApi + '/user', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          obj = result
          if(obj.success){
            console.log(obj.data)
            setUsers(obj.data)
            setContentLoading(false)
          }else{
            //setError(obj.errors)
            setContentLoading(false)
            setisMsgBox({
              open: true,
              status: 'error',
              title:'Users',
              msg: obj.message,
            })
          }
        })
        .then(()=>{
          console.log(obj)
          setContentLoading(false)
        })
  }
  const getTaxo = () => {
    const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 'taxo',
       {
        object : ["level"]
       },
       { headers: header })
        .then(res => {

          console.log(res.data.data.leve)
            setTaxo(res.data.data.level)
          })
        .catch((error) => {
          console.log(error)
        })
  }

  React.useEffect(() => {
    getUsers();
    getTaxo();
  }, []); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAddUser ref={addRef} config={props.config} refreshUserSave={afterSave} />
      <DrawerEditUser ref={editRef} config={props.config} refreshUserUpdate={afterUpdate} />
      <DrawerDeleteUser ref={deleteRef} config={props.config} refreshUserDelete={afterDelete} />
      
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Users 
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <Mui.Chip 
              label="Add New User" 
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.PersonAdd />}
              onClick={ ()=> {
                addRef.current.openForm(taxo);
              }}
              />
              <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                //setVariant('soft');
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />
      </Mui.Toolbar>
      {(filterEnable) ? (
        <Mui.Grid container 
            style={{
            backgroundColor:'#f8f8f8',
            padding:'10px 20px 10px 20px'
          }}>

            <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("keyword")} />
            </Mui.Box>
            <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                <Mui.FormControl size="small" className="inputField">
                    <Mui.TextField
                        select
                        size="small"
                        label="PerPage"
                        {...register("service_action")}
                    >
                        <Mui.MenuItem key={0} value={0}>25 Orders</Mui.MenuItem>
                        <Mui.MenuItem key={1} value={1}>50 Orders</Mui.MenuItem>
                        <Mui.MenuItem key={2} value={2}>100 Orders</Mui.MenuItem>
                        <Mui.MenuItem key={3} value={3}>All Orders</Mui.MenuItem>
                    </Mui.TextField>
                </Mui.FormControl>
            </Mui.Box>
            <Mui.Box sx={{margin: '7px 5px'}}>
                  <MuiJoy.Button
                    type="submit"
                    variant="solid" 
                    loading={loading} 
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.NavigateNext />}
                    loadingPosition="start"
                    > Show
                  </MuiJoy.Button>
            </Mui.Box>
        </Mui.Grid>
        ):('')}

      <Mui.Grid container spacing={2}>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
            ) : (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>ID</Mui.TableCell>
                                <Mui.TableCell>Username</Mui.TableCell>
                                <Mui.TableCell>Job</Mui.TableCell>
                                <Mui.TableCell>Name</Mui.TableCell>
                                <Mui.TableCell>Phone</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { users.map((user,key) => (
                          
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <Mui.TableCell component="th" scope="row">
                                    {user.id}
                                </Mui.TableCell>
                                <Mui.TableCell>{user.username}</Mui.TableCell>
                                <Mui.TableCell>{user.job_name}</Mui.TableCell>
                                <Mui.TableCell>{user.name}</Mui.TableCell>
                                <Mui.TableCell>{user.phone}</Mui.TableCell>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                    <MuiJoy.MenuButton
                                      slots={{ root: MuiJoy.IconButton }}
                                      slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                    >
                                      <MuiIcon.MoreVert />
                                    </MuiJoy.MenuButton>
                                    <MuiJoy.Menu placement="bottom-end">

                                    
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                            editRef.current.openForm(user.id,user,taxo);
                                        }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.Edit />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit
                                      </MuiJoy.MenuItem>
                                      
                                      <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                            deleteRef.current.openForm(user.id); 
                                          }}>
                                        <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                          <MuiIcon.NotInterested />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Delete
                                      </MuiJoy.MenuItem>
                                      
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                                      ))}
                        </Mui.TableBody>
                    </Mui.Table>
                </Mui.Grid>
            )}
    </Mui.Grid>

    </div>
  );
}

export default Users;
