import React,{ useRef,useState } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"


import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Header from '../lib/Header';
import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import GetStatus from '../lib/GetStatus';
import DrawerAddCustomer from '../drawer/DrawerAddCustomer';
import DrawerCancelServiceOrder from '../drawer/DrawerCancelServiceOrder';
import DrawerReviewServiceOrder from '../drawer/DrawerReviewServiceOrder';
import DrawerInfoServiceOrder from '../drawer/DrawerInfoServiceOrder';



const ServiceOrders = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  const [orders,setOrders] = React.useState([])
  const [loading,setLoading] = React.useState(false)

  const addRef = useRef();
  const reviewRef = useRef();
  const cancelRef = useRef();
  const infoRef = useRef();
  const formRef = useRef();

  const [start_rfs_date,set_start_rfs_date] = React.useState()
  const [end_rfs_date,set_end_rfs_date] = React.useState()

  const [taxo,setTaxo] = useState([]);
  const {register, formState: { errors }, handleSubmit, setValue } = useForm()


  const [page,setPage] = React.useState()

  const changePage = (event,value) => {
    setPage(value);
    getOrder()
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    service_action: '',
    status:'',
    so_date_start: '',
    so_date_end: '',
    perPage: 10

  })

  const onSubmit = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        service_action: data.service_action,
        status:data.status,
        so_date_start: data.so_date_start,
        so_date_end: data.so_date_end,
        perPage: data.perPage
      })
      getOrder()
  }


  const afterSave=()=>{
    getOrder()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Service Order',
      msg: 'Saving service order successfully.'
    })
  }

  const afterCancel=()=>{
    getOrder()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Cancel Service Order',
      msg: 'Cancel service order successfully'
    })
  }
  const afterUpdate=()=>{
    getOrder()
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Review Service Order',
      msg: 'Review service Order successfully'
    })
  }
  const getTaxo = () => {
    const header ={
        'Accept': 'application/json',
        'Authorization': cookies.token
      }
      axios.post( props.config.hostApi + 'taxo',
       {
        object : ["function","vendor",'snmp_version',"cluster","product","ont"]
       },
       { headers: header })
        .then(res => {
            setTaxo(res.data.data)
          })
        .catch((error) => {
          console.log(error)
        })
  }
  const getOrder = () => {
    setContentLoading(true)
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'service-order', 
     {
      search: filter.search,
      service_action: filter.service_action,
      status: filter.status,
      so_date_start: filter.so_date_start,
      so_date_end: filter.so_date_end,
      perPage: filter.perPage,
      page: page
  
    },
     { headers: header })
      .then(res => {
        console.log(res.data)
        setOrders(res.data.data)
        setPagination(res.data.pagination)
        setContentLoading(false)
          
      })
      .catch((error) => {
        console.log(error)
        setContentLoading(false)
      })
  };

  React.useEffect(() => {
    getOrder();
    getTaxo();
  }, [filter,page]); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAddCustomer ref={addRef} config={props.config} refreshParentCustomers={afterSave} />
      <DrawerReviewServiceOrder ref={reviewRef} config={props.config} refreshParentOrderUpdate={afterUpdate} />
      <DrawerCancelServiceOrder ref={cancelRef} config={props.config} refreshParentOrderCancel={afterCancel} />
      <DrawerInfoServiceOrder ref={infoRef} config={props.config} />
      
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Service Order ({pagination.totalRecords})
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <Mui.Chip 
              label="Add New Customer Service" 
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.BookmarkAdd />}
              onClick={ ()=> {
                addRef.current.openForm(taxo);
              }}
              />
            <Mui.Chip 
              label="Filter"
              variant="outlined" 
              style={{margin:'0px 3px', padding: '5px'}} 
              icon={<MuiIcon.ManageSearch />}
              onClick={ ()=> {
                //setVariant('soft');
                if(filterEnable)
                  setFilterEnable(false)
                else  
                  setFilterEnable(true)
              }}
              />
            
      </Mui.Toolbar>

      
      {(filterEnable) ? (
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Grid container 
                style={{
                backgroundColor:'#f8f8f8',
                padding:'10px 20px 10px 20px'
              }}>

                <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                    <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
                </Mui.Box>
                <Mui.Box sx={{minWidth:'160px',margin: '5px'}}>
                  <Mui.FormControl size="small" className="inputField">

                  <input type='hidden' {...register("service_action")} />
                      <Mui.TextField
                          select
                          size="small"
                          label="Type Order"
                          onChange={(event)=>(
                            event.target.value != 0 ? setValue('service_action',[event.target.value]) : setValue('service_action',[])
                            
                          )}
                      >
                          <Mui.MenuItem key={0} value={0}>All</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={1}>New Service</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={2}>Upgrade Service</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={6}>Downgrade Service</Mui.MenuItem>
                          <Mui.MenuItem key={4} value={4}>Suspend</Mui.MenuItem>
                          <Mui.MenuItem key={5} value={5}>Unsuspend</Mui.MenuItem>
                          <Mui.MenuItem key={6} value={3}>Terminate</Mui.MenuItem>
                      </Mui.TextField>
                    </Mui.FormControl>
                </Mui.Box>
                <Mui.Box sx={{minWidth:'160px',margin: '5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <input type='hidden' {...register("status")} />
                      <Mui.TextField
                          select
                          size="small"
                          label="Status Order"
                          onChange={(event)=>(
                            event.target.value != -1 ? setValue('status',[event.target.value]) : setValue('status',[])
                            
                          )}
                      >
                          <Mui.MenuItem key={-1} value={-1}>All</Mui.MenuItem>
                          <Mui.MenuItem key={0} value={0}>On Request</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={4}>Rejected</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={1}>Done</Mui.MenuItem>
                      </Mui.TextField>
                    </Mui.FormControl>
                </Mui.Box>
                <Mui.Box sx={{margin: '5px'}}>
                  <div style={{width:'340px',float:'left'}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                          <input type="hidden" value={start_rfs_date} {...register("so_date_start")} />
                          <DatePicker label="Start RFS Date" 
                                  sx={{ maxWidth: "165px " }}
                                  slotProps={{ textField: { size: 'small', border:'1px solid red' } }}  
                                  format="YYYY-MM-DD" 
                                  onChange={(event)=>{
                                      setValue('so_date_start',new Date(event).toLocaleDateString("sv-SE"))
                                      set_start_rfs_date( new Date(event).toLocaleDateString("sv-SE"))
                                  }} />
                      </LocalizationProvider> &nbsp; 
                      <LocalizationProvider dateAdapter={AdapterDayjs} >                          
                          <input type="hidden" value={end_rfs_date} {...register("so_date_end")} />
                          <DatePicker label="End RFS Date" 
                                  sx={{ maxWidth: "165px " }}
                                    slotProps={{ textField: { size: 'small' } }}  
                                    format="YYYY-MM-DD" 
                                    onChange={(event)=>{
                                        setValue('so_date_end',new Date(event).toLocaleDateString("sv-SE"))
                                        set_end_rfs_date( new Date(event).toLocaleDateString("sv-SE"))
                                    }} />
                      </LocalizationProvider>
                  </div>
                </Mui.Box>
                <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                    <Mui.FormControl size="small" className="inputField">
                        <Mui.TextField
                            select
                            size="small"
                            label="PerPage"
                            {...register("perPage")}
                        >
                            <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                            <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                            <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                            <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                        </Mui.TextField>
                    </Mui.FormControl>
                </Mui.Box>
                <Mui.Box sx={{margin: '7px 5px'}}>
                      <MuiJoy.Button
                        type="submit"
                        variant="solid" 
                        loading={loading} 
                        sx={{ margin: '0px 3px'}}
                        startDecorator={<MuiIcon.NavigateNext />}
                        loadingPosition="start"
                        > Show
                      </MuiJoy.Button>
                </Mui.Box>
            </Mui.Grid>
          </form>
        ):('')}
      
      <Mui.Grid container>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
            ) : (
              <>
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>SO ID</Mui.TableCell>
                                <Mui.TableCell>SO Date</Mui.TableCell>
                                <Mui.TableCell>SID</Mui.TableCell>
                                <Mui.TableCell>CID</Mui.TableCell>
                                <Mui.TableCell>RFS Date</Mui.TableCell>
                                <Mui.TableCell>Type Order</Mui.TableCell>
                                <Mui.TableCell>Package</Mui.TableCell>
                                <Mui.TableCell>Customer</Mui.TableCell>
                                <Mui.TableCell>Sales</Mui.TableCell>
                                <Mui.TableCell align='center'>Status</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        {orders.map((order,key) => (
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <Mui.TableCell component="th" scope="row">
                                    {order.id}
                                </Mui.TableCell>
                                <Mui.TableCell>{order.so_date}</Mui.TableCell>
                                <Mui.TableCell>{order.customer_service_id}</Mui.TableCell>
                                <Mui.TableCell>{order.customer_service ? order.customer_service.customer.id : ''}</Mui.TableCell>
                                <Mui.TableCell>{order.rfs_date}</Mui.TableCell>
                                <Mui.TableCell>{order.service_action_name}</Mui.TableCell>
                                <Mui.TableCell>{order.product_name}</Mui.TableCell>
                                <Mui.TableCell>{order.customer_name}</Mui.TableCell>
                                <Mui.TableCell>{order.requested_by_name}</Mui.TableCell>
                                <Mui.TableCell align="center"><GetStatus status={order.status} statusInfo={order.status_name} response={order.response} /></Mui.TableCell>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                    <MuiJoy.MenuButton
                                      slots={{ root: MuiJoy.IconButton }}
                                      slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                    >
                                      <MuiIcon.MoreVert />
                                    </MuiJoy.MenuButton>
                                    <MuiJoy.Menu placement="bottom-end">
                                      {order.status === 0 ? (
                                        <>
                                          <MuiJoy.MenuItem onClick={ ()=> {
                                                reviewRef.current.openForm(order.id,order,taxo);
                                            }}>
                                            <MuiJoy.ListItemDecorator>
                                              <MuiIcon.RateReview />
                                            </MuiJoy.ListItemDecorator>{' '}
                                            Review
                                          </MuiJoy.MenuItem>
                                          {order.response === 0 ? 
                                          (
                                            <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                                  cancelRef.current.openForm(order.id,order,taxo); 
                                                }}>
                                              <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                                <MuiIcon.NotInterested />
                                              </MuiJoy.ListItemDecorator>{' '}
                                              Cancel
                                            </MuiJoy.MenuItem>
                                          ):('')}
                                          
                                        </>
                                      ):('')}
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                            infoRef.current.openForm(order.id,order,taxo);
                                        }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.HelpOutline />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Info
                                      </MuiJoy.MenuItem>

                                      
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                        ))}
                        </Mui.TableBody>
                    </Mui.Table>
                </Mui.Grid>
                <Mui.Grid item xs={12} >
                  <Mui.Pagination 
                      sx={{ 
                        '& > .MuiPagination-ul': {
                          justifyContent: 'center',
                        },
                        p:2,
                      }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
              </Mui.Grid>
              </>
            )}
    </Mui.Grid>

    </div>
  );
}

export default ServiceOrders;
